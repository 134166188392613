import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiClient} from "../../../../tools/api-client";

@Injectable()
export class EFormMailingInfoService {

  constructor(private readonly apiClient: ApiClient) {
  }

  orderDevice(eFormId: String, mailingInfo: MailingInfo): Observable<void> {
    return this.apiClient.post(`/api/eform/${eFormId}/mailinginfo`, mailingInfo);
  }
}

export interface MailingInfo {
  name: string;
  streetAddress: string;
  postalCode: string;
  postalArea: string;
  emailAddress: string;
  phoneNumber: string;
}
