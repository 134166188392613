import {Injectable} from "@angular/core";
import {PrintFormModel} from "./print-form.component";
import {PDFDocument} from "pdf-lib";
import {map, mergeMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {ALL_MEASUREMENT_TYPES} from "../measurement-models";

@Injectable()
export class PdfFillerService {

  fillPdf(pdf: string, data: PrintFormModel) {
    return fromPromise(PDFDocument.load(pdf))
      .pipe(mergeMap(
        pdfDoc => {
          const form = pdfDoc.getForm();
          if (ALL_MEASUREMENT_TYPES.some(type => type === data.type)) {
            form.getTextField("patient_name").setText(data.name);
            form.getTextField("patient_ssn").setText(data.ssn);
          }
          form.getTextField("patient_id").setText(data.code);
          form.getTextField("unique_id").setText(data.uniqueId);
          if (form.getFields().some(field => field.getName() === "e-form_code")) {
            form.getTextField("e-form_code").setText(data.eFormCode);
          }

          form.flatten();
          return fromPromise(pdfDoc.save());
        }))
      .pipe(map(pdfData => {
          const file = new Blob([pdfData], {type: "application/pdf"});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
      );

  }
}
