<div class="container">
  <div class="mx-3 my-5">
    <eform-header
      *ngIf="isHomeMeasurement !== undefined"
      [isHomeMeasurement]="isHomeMeasurement"
    ></eform-header>
  </div>

  <hr/>

  <form>
    <app-form-selector
      [type]="type!"
      [formConfig]="formConfig!"
      [hidePostMeasurementFields]="hidePostMeasurementFields"
    ></app-form-selector>
  </form>

  <eform-mailing-info
    *ngIf="isHomeMeasurement && !mailingInfoSet"
  ></eform-mailing-info>

  <div
    data-testid="eform-wait-for-device-message"
    *ngIf="mailingInfoSet"
  >
    <p>{{ "eFormWaitForDeviceMessage" | localize }}</p>
  </div>


  <div class="mx-3 my-5">
    <h3 class="mb-3">{{ "eFormFooterTitle" | localize }}</h3>
    <p>{{ "eFormFooterText_paragraph1" | localize }}</p>
  </div>
</div>
