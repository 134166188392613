/* eslint-disable max-len */
import {LanguageCode, LocalizationStrings} from "./i18n-model";

export const LOCALIZATIONS = new Map<LanguageCode, LocalizationStrings>()
  .set(LanguageCode.EN_US, {
    navigationLogoDescription: "STP Medical Logo",

    loadingLabel: "Loading...",

    footerCustomerSupportLabel: "Customer support",
    footerCustomerSupportPhoneLabel: "tel.",

    navigationList: "Main Page",
    navigationUpload: "Upload",
    navigationActionsLog: "Actions Log",
    navigationUserList: "Users",
    navigationAddUsers: "Add Nurse",
    navigationLogout: "Logout",
    navigationBackgroundInformation: "Background Information Forms",
    navigationInitiateMeasurement: "Initiate measurement",
    navigationHospitals: "Hospitals",

    authGuardForbidden: "You do not have permission to access this resource",
    authTokenExpired: "Your session has expired. Please login again.",
    authAccessDenied: "You do not have the required permissions for this operation",
    authAuthenticateFailed: "There was a problem with authenticating your session. Please login again.",

    cellRendererCopyToClipboard: "Copy to Clipboard",

    loginFailed: "Login failed",
    loginFailedInvalidCredentials: "Invalid credentials",
    loginFormLabelUsername: "Username",
    loginFormLabelPassword: "Password",
    loginFormUsernameInvalidLabel: "Username is required (maximum 50 chars)",
    loginFormPasswordInvalidLabel: "Password is required (maximum 50 chars)",
    loginFormButtonLoginLabel: "Login",
    loginFormButtonLoginInProgressLabel: "Logging in...",

    loginFormButtonOtpLoginLabel: "Verify",
    loginFormLabelOtp: "Code",
    loginFormOtpInvalidLabel: "Code is required (6 digits)",
    loginFormButtonOtpLoginInProgressLabel: "Checking code...",
    loginFormOtpTitleLabel: "Email with one time password has been sent to your address",
    loginFormInvalidOtp: "Invalid code",

    changePasswordOldPasswordLabel: "Old password",
    changePasswordNewPasswordLabel: "New password",
    changePasswordRetypePasswordLabel: "Retype password",
    changePasswordStrongPasswordMessage:
      "8-20 characters, no white space, one number, one uppercase letter, one lowercase letter, one special character e.g. ! @ # ? ]",
    changePasswordLabel: "Change Password",
    changePasswordSuccessfulMessage: "Password changed successfully.",
    changePasswordFailedMessage: "Password change failed!",

    modalPromptAreYouSure: "Are you sure?",
    modalResponseYes: "Yes",
    modalResponseNo: "No",

    userListGetListFailed: "Error getting user list!",
    userListButtonLabelResetPassword: "Reset Password",
    userListButtonLabelResetPasswordInProgress: "Resetting ...",
    userListResetPasswordFailed: "Error resetting password!",
    userListResetPasswordSucceeded: "Password reset succeeded.",
    userListButtonLabelActivateUser: "Activate",
    userListButtonLabelActivateUserInProgress: "Activating ...",
    userListActivateUserFailed: "Error activating user!",
    userListActivateUserSucceeded: "User activation succeeded.",
    userListButtonLabelDeactivateUser: "Deactivate",
    userListButtonLabelDeactivateUserInProgress: "Deactivating ...",
    userListDeactivateUserFailed: "Error deactivating user!",
    userListDeactivateUserSucceeded: "User deactivation succeeded.",

    backgroundInformationTitle: "Background Information",

    measurementsGridColumnLabelCode: "Patient ID",
    measurementsGridColumnLabelUniqueId: "Archiving ID",
    measurementsGridColumnLabelStatus: "Status",
    measurementsGridColumnLabelUploadTime: "Upload time",
    measurementsGridColumnLabelInitiateTime: "Initiate time",
    measurementsGridColumnLabelNurse: "Nurse",
    measurementsGridColumnLabelDeliveryTime: "Delivery time",
    measurementsGridColumnLabelType: "Type",
    measurementsGridColumnLabelHospitalName: "Hospital",
    measurementsGridColumnLabelDoctor: "Doctor",
    measurementsGridColumnLabelAction: "Action",

    measurementsGridButtonLabelCopy: "Copy",
    measurementsGridButtonLabelAssign: "Assign",
    measurementsGridButtonLabelUploadReport: "Upload report",
    measurementsGridButtonLabelDownloadReport: "Download report",
    measurementsGridButtonLabelOpenAnalysis: "Open",
    measurementsGridButtonLabelCloseAnalysis: "Accept report",
    measurementsGridButtonLabelCloseAnalysisInProgress: "Closing...",
    measurementsGridButtonLabelReturnStudy: "Return to analysis",
    measurementsGridButtonLabelReturnStudyConfirm: "Confirm return",
    measurementsGridButtonTitleDownloadReport: "Report download",
    measurementsGridButtonTitleDownloadSecondaryReport: "ECG samples download",
    measurementsGridButtonTitleUploadMeasurement: "Upload measurement",
    measurementsGridButtonLabelStudyDetailsShow: "Show details",
    measurementsGridButtonLabelStudyDetailsHide: "Hide details",
    measurementsGridButtonLabelRejectStudy: "Reject",
    measurementsGridButtonLabelRejectStudyInProgress: "Rejecting...",
    measurementsGridColumnLabelDeviceSerialNumber: "S/N",

    measurementsGridTitleNewReport: "New Report!",

    closeEcgAnalysisFailed: "Close failed!",
    rejectStudyFailed: "Rejecting failed!",

    reportUploadButtonConfirmLabel: "Upload",
    reportUploadButtonCancelLabel: "Cancel",
    reportUploadButtonConfirmInProgressLabel: "Uploading...",

    reportUploadMeasurementStatusInvalid: "Invalid measurement status",
    reportUploadMeasurementFileNameInvalid: "Patient-ID does not match the recording. Please check the report-pdf.",
    reportUploadFailed: "Report upload failed",

    itemInfoTextLabelHospitalName: "Hospital",
    itemInfoTextLabelBmi: "BMI",
    itemInfoTextLabelEssScore: "ESS score",

    uploadFormInvalidLabelRequired: "Required field!",

    uploadFormOptionNo: "No",
    uploadFormOptionYes: "Yes",
    uploadFormOptionLittle: "A little",
    uploadFormOptionLot: "A lot",
    uploadFormOptionSometimes: "Sometimes",
    uploadFormOptionDaily: "Daily",
    uploadFormOptionWell: "Well",
    uploadFormOptionPoorly: "Poorly",
    uploadFormOptionNotAtAll: "Not at all",

    uploadFormInfoLabelHours: "h",
    uploadFormInfoLabelMinutes: "min",
    uploadFormInfoLabelTimes: "time(s)",

    uploadFormLabelCode: "Patient ID",
    uploadFormTypeFieldLabel: "Type",
    uploadFormLabelReportLanguage: "Report Language",

    uploadFormValidationErrorDefault: "Invalid data!",
    uploadFormInvalidLabelCode: "Maximum 20 characters. Apostrophes, special characters or personal identification numbers are not allowed.",

    uploadFormButtonLabel: "Upload",
    uploadFormButtonInProgressLabel: "Uploading...",

    uploadSucceeded: "Upload succeeded.",
    uploadFailed: "Upload failed.",
    uploadFailedCodeNotUnique: "Upload failed: ID already exists!",
    uploadFailedFileNameNotUnique: "This file has already been sent, choose the correct file!",
    uploadErrorCodeNotUnique: "ID already exists!",
    uploadErrorCannotGetLanguageSettings: "Failed to get hospital language settings!",

    sleepMeasurementFileInvalid: "A valid .dtx file must be given. Please choose the correct file.",
    ecgMeasurementFileInvalid: "A valid .c3s file must be given. Please choose the correct file.",
    bpMeasurementFileInvalid: "A valid .bpm file must be given. Please choose the correct file.",

    uploadFileFailed: "Uploading file failed!",
    uploadFileButtonCancel: "Cancel",
    uploadFileButtonDelete: "Delete",
    uploadFileButtonRetry: "Retry",

    uploadFormMeasurementFileLabel: "File",
    uploadFormInvalidLabelFile: "File must be uploaded!",

    enumMeasurementTypeSleep: "Sleep polygraphy",
    enumMeasurementTypeHolterEcg: "Holter ECG",
    enumMeasurementTypeSymptomHolter: "Symptom Holter",
    enumMeasurementTypeEcgAtrialFibrillation: "Atrial Fibrillation Screening",
    enumMeasurementTypeBloodPressure: "Blood Pressure",

    holterTimedTypeOfDurationDays1: "Holter 24h",
    holterTimedTypeOfDurationDays2: "Holter 48h",
    holterTimedTypeOfDurationDays3: "Holter 72h",
    holterTimedTypeOfDurationDays4: "Holter 4d",
    holterTimedTypeOfDurationDays5: "Holter 5d",
    holterTimedTypeOfDurationDays6: "Holter 6d",
    holterTimedTypeOfDurationDays7: "Holter 7d",

    uploadFormLabelGender: "Gender",
    uploadFormLabelGenderF: "Female",
    uploadFormLabelGenderM: "Male",
    uploadFormInvalidLabelGender: "Required!",

    uploadFormLabelAge: "Age",
    uploadFormInfoLabelAge: "yrs",
    uploadFormInvalidLabelAge: "Please review age!",

    uploadFormLabelHeight: "Height",
    uploadFormInfoLabelHeight: "cm",
    uploadFormInvalidLabelHeight: "Please review height!",

    uploadFormLabelWeight: "Weight",
    uploadFormInfoLabelWeight: "kg",
    uploadFormInvalidLabelWeight: "Please review weight!",

    uploadFormLabelWaistline: "Waistline",
    uploadFormInfoLabelWaistline: "cm",
    uploadFormInvalidLabelWaistline: "Please review waistline!",

    uploadFormLabelCholesterol: "Cholesterol (LDL)",
    uploadFormInfoLabelCholesterol: "mmol/l",
    uploadFormInvalidLabelCholesterol: "Please review cholesterol!",

    uploadFormLabelOverweight: "Overweight",

    uploadFormLabelProfession: "Profession",
    uploadFormInvalidLabelProfession: "At most 40 chars!",

    uploadFormLabelHypertension: "Hypertension",
    uploadFormLabelDiabetes: "Diabetes",
    uploadFormLabelOverbite: "Overbite",
    uploadFormLabelCloggedNasalPassage: "Clogged Nasal Passage",
    uploadFormLabelAllergicRhinitis: "Allergic Rhinitis",
    uploadFormLabelSmallChin: "Small Chin",
    uploadFormLabelCoronaryArteryDisease: "Coronary Artery Disease",
    uploadFormLabelCoronaryInsufficiency: "Coronary Insufficiency",
    uploadFormLabelCerebrovascularDisorders: "Cerebrovascular Disorders",
    uploadFormLabelKidneyDisease: "Kidney Disease",

    uploadFormLabelSmoking: "Smoking",
    uploadFormLabelUseOfSleepingPills: "Use of sleeping pills",
    uploadFormLabelAlcoholConsumption: "Alcohol consumption (doses per week)",

    alcoholConsumptionOptionOver20: "over 20",

    uploadFormHeaderFrequency: "Is there...",
    uploadFormLabelMorningHeadaches: "Morning headaches",
    uploadFormLabelBreathingLoss: "Breathing loss during sleep",
    uploadFormLabelFluttering: "Fluttering while sleeping",
    uploadFormLabelSnoring: "Snoring",
    uploadFormLabelRestlessSleep: "Restless sleep, sensitive waking",
    uploadFormLabelDaytimeSleepiness: "Daytime sleepiness",
    uploadFormLabelDryMouthInMorning: "Dry mouth in the morning",
    uploadFormLabelNeedToUrinate: "Need to urinate during the night",

    uploadFormOptionFrequencyDaily: "Daily",
    uploadFormOptionFrequencyWeekly: "Weekly",
    uploadFormOptionFrequencyMonthly: "Monthly",
    uploadFormOptionFrequencyRarely: "Rarely",

    uploadFormHeaderProbability: "The probability of falling asleep when ...",
    uploadFormLabelSittingReading: "I'm sitting reading",
    uploadFormLabelWatchingTelevision: "I'm watching television",
    uploadFormLabelSittingPassive: "I'm sitting passively",
    uploadFormLabelTravelInCarForAnHour: "I travel in the car for an hour",
    uploadFormLabelLyingDownInAfternoon: "Lying down in the afternoon",
    uploadFormLabelSittingTalkingToSomeone: "I'm sitting talking to someone",
    uploadFormLabelSitInPeaceAfterNonAlcoholicLunch: "I sit restfully after a non-alcoholic lunch",
    uploadFormLabelSitInCarAfterItHasStoppedForFewMinutes: "I sit in the car after it has stopped for a few minutes",

    uploadFormOptionProbabilityZero: "Zero",
    uploadFormOptionProbabilitySmall: "Small",
    uploadFormOptionProbabilityModerate: "Moderate",
    uploadFormOptionProbabilityLarge: "Large",

    uploadFormLabelDiagnosedHeartDisease: "Diagnosed heart disease / arrhythmia",

    uploadFormLabelCurrentMedication: "Current medication",
    uploadFormLabelReasonForStudy: "Reason for participating in study",

    uploadFormHeaderSleepRegistrationEvents: "Sleep registration events:",

    uploadFormLabelSleepQuality: "Did you sleep better or worse than usual?",

    uploadFormOptionSleepQualityBetter: "Better",
    uploadFormOptionSleepQualitySame: "No difference",
    uploadFormOptionSleepQualityWorse: "Worse",

    uploadFormLabelFallAsleepDuration: "Did falling asleep last as usual?",

    uploadFormOptionFallingAsleepDurationShorter: "Shorter",
    uploadFormOptionFallingAsleepDurationSame: "As usual",
    uploadFormOptionFallingAsleepDurationLonger: "Longer",

    uploadFormLabelSleepHours: "Estimated number of sleep hours",
    uploadFormLabelMinutesAwakeAfterMeasurementStart: "Estimated number of minutes awake after start of measurement",
    uploadFormLabelAwakeningsDuringMeasurement: "How many times did you wake up during the night?",

    uploadFormLabelSleepMeasurementNight: "How did you sleep on the night of measurement?",

    uploadFormLabelGoToBedTime: "When did you go to bed?",
    uploadFormLabelWakeUpTime: "When did you wake up?",
    uploadFormInvalidLabelGoToBedWakeUp: 'Required! "Go to bed time" must come before "Wake up time".',

    uploadFormLabelParentsHeartAttack60: "Have either of your parents had a heart attack under the age of 60?",
    uploadFormLabelParentsStroke75: "Have either of your parents had a stroke under the age of 75?",

    uploadFormLabelSleepDiary: "Sleep diary",
    uploadFormLabelPatientFeedback: "Patient's feedback",
    uploadFormLabelNurseComments: "Nurse's comments",

    uploadFormLabelDiary: "Diary",
    uploadFormLabelAbnormalHeartbeats: "Feelings of abnormal heartbeats",
    uploadFormLabelSlowPulse: "Feelings of slow pulse",
    uploadFormLabelFastPulse: "Feelings of fast pulse",
    uploadFormLabelPalpitation: "Palpitation",
    uploadFormLabelIrregularPulse: "Irregular pulse",
    uploadFormLabelDizziness: "Dizziness",
    uploadFormLabelLossOfConsciousness: "Sudden loss of consciousness",
    uploadFormLabelBlackouts: "Blackouts",
    uploadFormLabelChestPain: "Chest pain",
    uploadFormLabelShortnessOfBreath: "Shortness of breath",

    uploadFormLabelSymptom: "Symptom",
    uploadFormLabelSymptoms: "Symptoms",
    uploadFormInvalidLabelEcgSymptoms:
      "At least one symptom must be defined. Date, time and description of at most 60 characters must be set.",

    uploadFormLabelMeasurementStartTime: "Measurement start",

    measurementStatusUploaded: "Uploaded",
    measurementStatusReadyForAnalysis: "Ready for analysis",
    measurementStatusReadyForPostAnalysis: "Ready for post-analysis",
    measurementStatusDone: "Done",
    measurementStatusRejectedByDataOfficer: "Rejected",
    measurementStatusPreAnalysed: "Pre-analysed",
    measurementStatusInitiated: "Initiated",

    nurseMeasurementStatusProcessing: "Processing",
    nurseMeasurementStatusRejected: "Rejected",
    nurseMeasurementStatusDone: "Done",

    newUsersLabelHospitalName: "Hospital",
    newUsersLabelEmailAddresses: "Email Addresses",
    newUsersInvalidLabelEmailAddresses: "Email in wrong format",
    newUsersInvalidLabelEmailAddressesLength: "Email too long (maximum 50 characters)",
    newUsersPlaceholderEmailAddresses: "one email per line",
    newUsersAddButtonLabel: "Add Users",
    newUsersAddButtonLoadingLabel: "Adding ...",
    newUsersQuestionConfirmSubmit: "Do you want to add these users to hospital",
    newUsersAddFailedInvalidEmails: "Invalid emails",
    newUsersAddFailedExistingEmails: "Existing emails",
    newUsersAddFailedUnsentEmails: "Could not send emails to",
    newUsersAddNursesFailedPost: "Error adding nurses!",
    newUsersAddNursesSuccessfulPost: "Nurses added successfully.",

    analysisMessageLoadBpDataFailed: "Error loading blood pressure data!",
    analysisMessageFailedGet: "Error loading measurement analysis!",
    analysisButtonSave: "Save",
    analysisLabelSaveInProgress: "Saving...",
    analysisMessageSaveFailed: "Error saving measurement analysis!",
    analysisMessageSaveSuccess: "Measurement saved successfully",
    analysisLabelStartTime: "Start time",
    analysisLabelEcgSamplesDocument: "ECG samples",
    analysisButtonPreviewReport: "Preview report",
    finishAnalysisFailed: "Failed to finish analysis",
    analysisLabelPatientStatusComment: "Patient status comments",
    analysisLabelFindings: "Findings",
    analysisLabelConclusion: "Conclusion",
    analysisLabelDiagnosis: "Diagnosis",
    analysisLabelButtonFinish: "Finish",
    analysisLabelButtonFinishInProgress: "Finishing...",
    analysisLabelButtonFinishConfirm: "Confirm Finish",
    analysisLabelButtonFinishCancel: "Cancel",
    analysisFormValidationError: "Invalid data, please check form errors!",
    analysisFormInvalidLabelRequired: "Required field!",
    analysisFormInvalidEcgFile: "Select a valid PDF file. File name must contain ID.",
    analysisLabelExternalAnalysisUrl: "Cardiologs url: ",

    analysisBpChartLegendDiastolic: "Diastolic",
    analysisBpChartLegendSystolic: "Systolic",
    analysisBpChartLegendHeartRate: "Heart rate",

    bpFindingsActiveTime: "Active Time",
    bpFindingsSleepTime: "Sleep Time",
    bpFindingsWholeDay: "Whole Day",
    bpFindingsAverageSystolicBP: "Average Systolic Blood Pressure",
    bpFindingsAverageDiastolicBP: "Average Diastolic Blood Pressure",
    bpFindingsAverageSystolicRange: "Systolic Range",
    bpFindingsAverageDiastolicRange: "Diastolic Range",
    bpFindingsSystolicBPload: "Systolic BP Load",
    bpFindingsDiastolicBPload: "Diastolic BP Load",
    bpFindingsAveragePuls: "Average Heartbeat",

    bpSmallerThanActive: "< active",

    reportLabelPatient: "Patient",
    reportPatientDataPageTitle: "Patient data",
    reportPageLabel: "Page",
    reportUniqueIdLabel: "Archiving ID",
    reportFindingsSectionTitle: "Findings",

    bpReportBPmonitoring24h: "24-hour blood pressure monitoring",

    ecgReportSymptomHolterDisclaimer: "Symptom Holter is a study in which the specialist does not go through all recordings. In this case any asymptomatic arrhythmias may go completely undetected.",
    ecgReportSymptomFindingsTitle: "Symptom findings",
    ecgReportAtrialFibrillationTitle: "ECG Atrial Fibrillation",
    ecgReportAtrialFibrillationDisclaimer: "Atrial Fibrillation screening is a study where the specialist does not go through the recorded material in entirety. In this case, some of the possible arrhythmias may not be detected and analyzed at all.",
    ecgReportLabelSymptom: "Symptom",

    downloadedReportFilenamePrefix_sleep: "sleep",
    downloadedReportFilenamePrefix_ecgHolter: "holter",
    downloadedReportFilenamePrefix_symptomHolter: "symptom-holter",
    downloadedReportFilenamePrefix_ecgAtrialFibrillation: "atrial-fibrillation",
    downloadedReportFilenamePrefix_samples: "samples",
    downloadedReportFilenamePrefix_bloodPressure: "blood-pressure",

    ecgDataOfficerFileshareFolderInvalid: "DataOfficer's CubeDB folder missing or not unique",
    assignDoctorFailed: "Assign doctor failed!",

    errors_default: "Unexpected error",
    errors_downloadReportFailed: "Download report failed",

    assignDoctorSelectorLabelCurrent: "current",

    timeFilterFromLabel: "From:",
    timeFilterToLabel: "To:",
    timeFilterSearchButton: "Search",
    timeFilterErrorMessage: 'The "From" date is after "To" date',
    timeFilterLoadingLabel: "Loading...",

    measurementListCount: "Number of rows:",
    measurementListAllColumnFilterOption: "All",
    invalidDeviceSerialNumber: "C3S and edf file serial numbers do not match",

    changeLanguageLabel: "Change language",
    englishLang: "🇬🇧 English",
    finnishLang: "🇫🇮 Finnish",
    displayLanguageLabel: "Display language",
    changeLanguageFailedMessage: "Language change failed",

    initiateMeasurementReasonForStudyInvalid: "Reason for study is mandatory",
    initiateFormButtonLabel: "Initiate",
    initiateFormButtonInProgressLabel: "Initiating",
    initiateFailed: "Initiate failed: Server Error",

    reasonForStudyEmpty: "Reason for study is mandatory",
    invalidHospitalNameLength: "Hospital name too long (maximum 300 characters)",
    initiatedMeasurementFailedGet: "Error loading measurement",
    orderFormLabelOperatorHospital: "Operator hospital",
    orderFormLabelOperatorHospitalInvalid: "Operator hospital is mandatory",
    navigationOrderMeasurement: "Order measurement",

    orderFormButtonLabel: "Order",
    orderFormButtonInProgressLabel: "Ordering",
    orderFailed: "Order failed: Server Error",
    operatorHospitalsFailedGet: "Failed to load operator hospitals",

    printOrderFormLabelSsn: "SSN",
    printOrderFormInvalidLabelSsn: "SSN invalid",
    printOrderFormLabelName: "Name",
    printOrderFormInvalidLabelName: "Name required",
    printOrderFormButtonLabel: "Print",
    printOrderFormButtonInProgressLabel: "Printing..",
    printOrderFormValidationErrorDefault: "Values Invalid",
    printOrderFormLabelCode: "Patient id",
    printOrderFormLabelInitiatedTime: "Initiated at",
    printOrderFormLabelOperatorHospital: "Operator hospital",
    printOrderFormLabelOrderingHospital: "Ordering hospital",
    printOrderFormLabelUniqueId: "Unique id",
    printOrderFormErrorGettingData: "Error getting data for printing order form",
    printOrderFormType: "Type",

    measurementsGridButtonTitlePrintOrder: "Print...",
    orderingNurseCheckboxLabel: "Ordering nurse",

    hospitalsTitle: "Hospitals",
    hospitalsFailedToGet: "Failed to get hospital list",

    editHospitalFailedToLoadHospitalDetails: "Failed to load hospital details",
    editHospitalFailedToAddOperator: "Failed to add operator",
    editHospitalOperatorHospitals: "Operator hospitals",
    editHospitalChooseOperatorText: " can order measurements from these hospitals",
    measurementsGridButtonTitlePrintForm: "Print measurement data form",
    printOrderFormInvalidLabelType: "Invalid type",
    printOrderFormInvalidLabelCode: "Invalid code",
    printOrderFormInvalidLabelUniqueId: "Invalid unique Id",
    printOrderFormInvalidLabelInitiatedTime: "Invalid initiated time",
    printFormLabelLanguage: "Language",
    printFormInvalidLabelLanguage: "Invalid language",
    printFormButtonLabel: "Print",
    swedishLang: "🇸🇪 Swedish",
    russianLang: "🇷🇺 Russian",
    printFormPatientTemplateNotFound: "Form template not found",
    printFormFetchError: "Template fetching failed",
    printOrderFormNoOperatorInformation: "Operator hospital information not available",

    measurementsGridTitleJobPending: "Pending",
    measurementsGridTitleJobFailed: "Job failed, check what happened",
    measurementsGridTitleJobSucceeded: "Success",

    eFormCodeInputErrorLabel: "Password insufficient",
    eFormCodeInputLabel: "Please, enter your password here:",
    eFormLandingPageTitle: "Background data form login",
    eFormLandingPageError: "Incorrect password",
    eFormGetFormError: "Failed to get data. Please try again later.",
    eFormUpdateFormError: "Failed to save data. Please try again later.",

    eFormHeaderTitle: "Electric background data form",
    eFormHeaderText_paragraph1: "Thank you for filling out this form in electronic format. This helps us to alleviate the workload of the healthcare personnel and therefore helps other patients as well.",
    eFormHeaderText_paragraph2: "Please fill out the form carefully. Accurate information helps the analyzing specialist to understand the situation in detail. Do not include any personal information e.g. your personal identification number or contact information. You may add information during the entire examination until you return the equipment. Please remember to return the paper data form, even if you did not fill any information therein.",
    eFormFooterTitle: "Thank you!",
    eFormFooterText_paragraph1: "Your answers have been saved automatically. Please double check that you have answered all the questions. If for some reason it was not possible to fill in a section, you can complete it on the paper form as well. Return the device and paper data form according to the instructions you received.",
    initiatedMeasurementEFormLanguageNotSaved: "E-Form language not saved",

    eFormSubmitButton: "Submit",
    textTooLongError: "Text too long",

    editHospitalFailedToUpdateHospitalMeasurementTypes: "Failed to save allowed measurement types for hospital",
    editHospitalAllowedTypes: "Hospital measurement types",
    editHospitalAllowedTypesText: "can upload and initiate these types:",
    editHospitalSubmitAllowedTypes: "Save types",

    errorCannotGetHospitalTypes: "Error try again later",
    editHospitalSuccessfullyUpdatedMeasurementTypes: "Successfully updated hospital measurement types",
    measurementsGridButtonLabelDeleteMeasurement: "Delete",
    measurementsGridButtonLabelDeleteMeasurementTooltip: "Delete initiated measurement",
    measurementsGridButtonLabelDeleteMeasurementInProgress: "Deleteing...",
    deletingMeasurementFailed: "Deleting measurement failed",
    measurementsGridButtonLabelShowComments: "Comments",
    modalResponseClose: "Close",

    commentAddingFailed: "Adding comment failed",
    commentFetchingFailed: "Fetching comments failed",

    errorCannotGetSummary: "Getting summary failed",

    labelHospitalName: "Hospital Name",

    hospitalInformationUpdateError: "Failed to update hospital details",
    hospitalInformationUpdateSuccess: "Hospital details update successful",
    hospitalInformationUpdateButton: "Save hospital details",
    hospitalInformationPublicName: "Public name",
    hospitalInformationPublicNameError: "Public name must be less than 100 characters",
    hospitalInformationTitle: "Hospital Information",
    hospitalInformationAddress: "Address",
    hospitalInformationAddressError: "Address must be less than 1000 characters",
    hospitalInformationCity: "City",
    hospitalInformationCityError: "City must be less than 100 characters",
    hospitalInformationOperatorAppointmentDescription: "Appointment description",
    hospitalInformationOperatorAppointmentDescriptionError: "Appointment description must be less than 1000 characters",
    hospitalInformationPostalCode: "Postal code",
    hospitalInformationPostalCodeError: "Postal code must be less than 100 characters",

    hospitalPatientFormTemplatesTitle: "Select which patient form will be used for measurement type",
    hospitalPatientFormTemplatesButton: "Update",
    hospitalPatientFormTemplatesError: "Failed to set hospital patient templates",
    hospitalPatientFormTemplatesSuccess: "Successfully set hospital patient templates",

    editMeasurementDataButton: "Edit",
    editMeasurementDataUpdateInProgressLabel: "Updating...",
    editMeasurementDataUpdateButtonLabel: "Update",
    editMeasurementDataFormInvalid: "Enter valid values",
    editMeasurementDataUpdateFailureMessage: "Updating patient data failed",
    editMeasurementDataUpdateSuccessMessage: "Updating patient data succeeded",

    measurementGridAssignTechnicianSuccessful: "Technician assigned successfully",
    measurementGridAssignTechnicianError: "Technician assign failed",
    measurementsGridAssignTechnicianButton: "Assign Technician",
    measurementsGridAssignTechnicianInProgress: "Assigning...",
    measurementsGridColumnLabelTechnician: "Technician",
    measurementsGridButtonLabelAssignDoctor: "Assign Doctor",

    characterCounterCharactersRemaining: "characters remaining.",
    eformSymptomWarningLabel: "Max 60 characters.",

    navigationLabelOldServiceModel: "Old service model",

    hospitalLanguageSettingsTitle: "Hospital language",
    hospitalLanguageSelect: "Language",
    hospitalReportLanguageToggleCheckbox: "Enable report language",
    hospitalLanguageSettingsUpdateButton: "Update language settings",
    hospitalLanguageSettingsUpdateFailure: "Updating language settings failed",
    hospitalLanguageSettingsUpdateSuccess: "Updating language settings success",

    uploadGetContextFailed: "Failed to open upload data form. Please try again.",

    measurementGridReportLanguage: "Report language",

    analysisPatientStatusCommentsGeneratorDoneButton: "Done",
    analysisPatientStatusCommentsRegenerateButton: "Regenerate",

    generator_patientStatusComments_reasonForStudy_arrhythmiaSensations: "Arrhythmia sensations",
    generator_patientStatusComments_reasonForStudy_other: "Other",
    generator_patientStatusComments_rhythmAffectingMedication_no: "No",
    generator_patientStatusComments_rhythmAffectingMedication_yes: "Yes",
    generator_patientStatusComments_symptomsDuringRecording_no: "No",
    generator_patientStatusComments_symptomsDuringRecording_yes: "Yes",
    generator_patientStatusComments_symptomsDuringRecording_noDiary: "No diary",

    generator_label_patientStatusComments_reasonForStudy: "Reason for study",
    generator_label_patientStatusComments_rhythmAffectingMedication: "Rhythm affecting medication",
    generator_label_patientStatusComments_symptomsDuringRecording: "Symptoms during recording",

    analysisPatientStatusCommentsConfirmRegenerateButton: "Regenerate",
    analysisPatientStatusCommentsCancelRegenerateButton: "Cancel",
    analysisRegenerationWarningLabel: "Regenerating deletes the text you have written manually!",

    generator_label_findings_prevailingRhythm: "Prevailing rythm",
    generator_label_findings_atrialBeats: "Atrial beats",
    generator_label_findings_atrialTachycardias: "Atrial tachycardias",
    generator_label_findings_avConduction: "AV conduction",
    generator_label_findings_averageHr: "Average HR",
    generator_label_findings_commentAtrial: "Comment atrial",
    generator_label_findings_commentVentricular: "Comment ventricular",
    generator_label_findings_deltaWave: "Delta wave",
    generator_label_findings_maximumHr: "Maximum HR",
    generator_label_findings_minimumHr: "Minimum HR",
    generator_label_findings_pausesOver2_5s: "Pauses over 2,5 s",
    generator_label_findings_qrsWidth: "QRS width",
    generator_label_findings_qtTime: "QT interval",
    generator_label_findings_stSegment: "ST segment",
    generator_label_findings_symptomDiaryComment: "Symptom diary comment",
    generator_label_findings_ventricularBeats: "Ventricular beats",
    generator_button_findings_getExternalAnalysis: "Import ECG data",

    generator_findings_option_normal: "N",
    generator_findings_option_other: "Other",

    uploadFileFailedFileAlreadyUploaded: "This file has already been uploaded on \${uploadTime} with Patient ID \${measurementCode} by \${uploadingNurse}",

    reportUploadFileNotForThisMeasurement: "The identification of the report and the measurement do not match!",

    analysisDataOutdatedErrorLabel: "The parameters of the ECG data have been changed in the analysis software, and they do not correspond to the figures in the statement. Import the ECG data again.",

    generator_label_findings_ventricularTachycardias: "Ventricular tachycardias",
    externalFindingsMissingFieldsWarningLabel: "Some external findings missing, some sentences may not be generated",

    dataOfficerMeasurementStatusDeleted: "Deleted",
    measurementsGridButtonLabelRestoreMeasurement: "Restore",
    measurementsGridButtonLabelRestoreMeasurementInProgress: "Restoring",
    restoreMeasurementFailed: "Restore measurement failed",
    restoreMeasurementSucceeded: "Restore measurement succeeded",
    confirmDeleteMeasurement: "Are you sure you wish to delete initiated measurement ${code}? This identifier cannot be used again.",
    editHolterTypeButton: "Edit Holter type",
    setHolterTypeButton: "Set Holter type",

    measurementsGridButtonLabelReassignDoctor: "Reassign",
    reassignMeasurementSucceeded: "Reassign successful",
    reassignMeasurementFailed: "Reassign failed",
    assignFailedDoctorDoesNotHaveAccessToExternalAnalysis: "Selected doctor does not have access to cardiologs",
    confirmReassignDoctor: "Are you sure you want to reassign doctor?",

    hospitalPutHospitalInChainButton: "Set chain",
    hospitalPutHospitalInChainTitle: "Set chain for hospital",
    hospitalPutHospitalInChainSuccess: "Chain set successfully",
    hospitalPutHospitalInChainFailure: "Setting chain failed",
    hospitalPutHospitalInChainSelect: "Hospital chain",

    eFormLandingPageTitle_304: "Perustietokaavake.fi",
    eFormLandingPageGreeting: "Welcome!",
    eFormLandingPageDescription: "is an anonymous symptom diary on the Internet. Your personal data will not be requested nor saved at any stage. Enter the password you received in the adjacent field.",
    eFormLandingPageThankYou: "Thank you for participating!",

    measurementDetailsFileNameLabel: "File name",
    measurementDetailsDataSizeLabel: "File size",

    homeHolterLandingPageTitle: "Koti-Holter.fi",
    homeHolterLandingPageDescription: "is an Internet-based healthcare service channel available to both private and public sector customers. To log in, enter the password you received in the adjacent field.",
    homeHolterLandingPageCustomerService: "Customer service",

    hospitalNurseSettingsCombinedReportCheckbox: "Combine reports for nurses",
    hospitalNurseSettings2FaCheckbox: "Enable 2fa for nurses from this hospital",
    hospitalNurseSettingsTitle: "Nurse settings",
    hospitalNurseSettingsUpdateButton: "Update settings",
    hospitalNurseSettingsUpdateError: "Updating nurse settings failed",
    hospitalNurseSettingsUpdateSuccess: "Updating nurse settings successful",
    externalAnalysisUrlLabel: "Link to ECG data",

    measurementTypeLabelHomeHolter: "Home Holter",
    measurementTypeLabelHomeHolter24h: "Home Holter 24h",
    measurementTypeLabelHomeHolter48h: "Home Holter 48h",
    measurementTypeLabelHomeHolter7d: "Home Holter 7d",

    measurementDetailsSpecifiedHomeHolterDurationLabel: "Duration of measurement",

    jobAlreadyExistsMessage: "Measurement still processing. Please wait or contact support.",

    editHospitalAllowedTypesHeaderNonHome: "Hospital",
    editHospitalAllowedTypesHeaderHome: "Home",

    homeHolterDuration1dTypeLabel: "Home Holter 24h",
    homeHolterDuration2dTypeLabel: "Home Holter 48h",
    homeHolterDuration3dTypeLabel: "Home Holter 72h",
    homeHolterDuration4dTypeLabel: "Home Holter 4d",
    homeHolterDuration5dTypeLabel: "Home Holter 5d",
    homeHolterDuration6dTypeLabel: "Home Holter 6d",
    homeHolterDuration7dTypeLabel: "Home Holter 7d",
    homeHolterTypeLabel: "Home Holter",

    homeSleepTypeLabel: "Home Sleep Polygraphy",
    homeSymptomHolterTypeLabel: "Home Symptom Holter",
    homeEcgAtrialFibrillationTypeLabel: "Home Atrial Fibrillation",
    homeBloodPressureTypeLabel: "Home Blood Pressure",

    finishAnalysisFailedBecauseSamplesDocumentMissing: "The ECG report in the analysis software is not created yet!",

    mailingInfoFormNameLabel: "Name",
    mailingInfoFormNameInvalidLabel: "Invalid name",
    mailingInfoFormStreetAddressLabel: "Postal address",
    mailingInfoFormStreetAddressInvalidLabel: "Invalid postal address",
    mailingInfoFormEmailAddressLabel: "E-mail",
    mailingInfoFormPhoneNumberLabel: "Phone number",
    mailingInfoFormPhoneNumberInvalidLabel: "Invalid phone number",
    mailingInfoFormPermissionLabel: "I give permission for storing mailing data until I return the device",
    mailingInfoFormOrderButtonLabel: "Order device",
    mailingInfoFormDeviceOrderThankYouMessage: "Thank you for ordering the device!",
    mailingInfoFormDeviceOrderFailedErrorMessage: "Ordering failed! Try again please.",

    eFormWaitForDeviceMessage: "Waiting for device.",

    dataOfficerFolderHasNotFinishedSyncing: "The measurement folder's synchronization to OneDrive is not yet complete.",

    measurementsGridColumnLabelMailingInfoSetAt: "Mailing info set at",
    measurementsGridColumnLabelHomeStatus: "Status",

    homeMeasurementStatusLabelWaitingForMailingInfo: "Waiting for device order",
    homeMeasurementStatusLabelMailingToPatient: "Waiting for device delivery",

    navigationOrderHomeMeasurement: "Order home measurement",
    nurseMeasurementStatusOrdered: "Ordered",
    measurementsGridButtonLabelMailDevice: "Device mailed",
    mailingConfirmationButtonLabel: "Confirm device mailed",
    mailingConfirmationButtonInProgressLabel: "Confirmation in progress",
    mailingDeviceConfirmationSuccess: "Device mailed confirmed",
    mailingDeviceConfirmationFailure: "Failed to confirm device mailed",

    mailDevicePageLabelDeviceSerialNumber: "Serial number:",
    mailDevicePageDeviceSerialNumberError: "Serial number empty or device in use",

    eFormHomeHeaderTitle: "Home measurement e-form title",
    eFormHomeHeaderText_paragraph1: "Home measurement e-form instructions",
    eFormHomeHeaderText_paragraph2: "",
    mailingConfirmationPrintMailingInfoButtonLabel: "Print envelope",

    mailingConfirmationPrintingMailingInfoSuccess: "Successfully copied mailing info to clipboard!",
    mailingConfirmationPrintingMailingInfoFailure: "Failed to get mailing info!",
    mailingInfoFormPostalCodeLabel: "Postal code",
    mailingInfoFormPostalCodeInvalidLabel: "Postal code invalid",
    mailingInfoFormPostalAreaLabel: "Postal area",
    mailingInfoFormPostalAreaInvalidLabel: "Postal area invalid",

    measurementsGridColumnLabelDeviceMailedAt: "Device mailed at",

  })
  .set(LanguageCode.FI_FI, {
    navigationLogoDescription: "STP Medical Logo",

    loadingLabel: "Lataa...",

    footerCustomerSupportLabel: "Asiakastuki",
    footerCustomerSupportPhoneLabel: "puh.",

    navigationList: "Etusivu",
    navigationUpload: "Aineiston lataus",
    navigationActionsLog: "Toimintaloki",
    navigationUserList: "Käyttäjät",
    navigationAddUsers: "Lisää hoitaja",
    navigationLogout: "Kirjaudu ulos",
    navigationBackgroundInformation: "Perustietokaavakkeet",
    navigationInitiateMeasurement: "Alusta tutkimus",
    navigationHospitals: "Käyttöpaikat",

    authGuardForbidden: "Sinulla ei ole lupaa käyttää tätä resurssia",
    authTokenExpired: "Istuntosi on vanhentunut. Ole hyvä ja kirjaudu sisään uudelleen.",
    authAccessDenied: "Sinulla ei ole vaadittuja oikeuksia tähän toimintoon",
    authAuthenticateFailed: "Istunnon todentamisessa tapahtui ongelma. Ole hyvä ja kirjaudu sisään uudelleen.",

    cellRendererCopyToClipboard: "Kopioi leikepöydälle",

    loginFailed: "Sisäänkirjautuminen epäonnistui",
    loginFailedInvalidCredentials: "Käyttäjätunnus tai/ja salasana väärin",
    loginFormLabelUsername: "Käyttäjätunnus",
    loginFormLabelPassword: "Salasana",
    loginFormUsernameInvalidLabel: "Käyttäjätunnus vaaditaan (enintään 50 merkkiä)",
    loginFormPasswordInvalidLabel: "Salasana vaaditaan (enintään 50 merkkiä)",
    loginFormButtonLoginLabel: "Kirjaudu",
    loginFormButtonLoginInProgressLabel: "Kirjaudutaan sisälle...",

    loginFormButtonOtpLoginLabel: "Vahvista",
    loginFormLabelOtp: "Turvakoodi",
    loginFormOtpInvalidLabel: "Turvakoodi vaaditaan",
    loginFormButtonOtpLoginInProgressLabel: "Tarkistetaan turvakoodia...",
    loginFormOtpTitleLabel: "Turvakoodi on lähetetty sähköpostiosoitteeseesi",
    loginFormInvalidOtp: "Väärä turvakoodi",

    changePasswordOldPasswordLabel: "Vanha salasana",
    changePasswordNewPasswordLabel: "Uusi salasana",
    changePasswordRetypePasswordLabel: "Kirjoita salasana uudelleen",
    changePasswordStrongPasswordMessage:
      "8-20 merkkiä, ei välilyöntejä, yksi numero, yksi iso kirjain, yksi pieni kirjain, yksi erikoismerkki, esim. ! @ #? ]",
    changePasswordLabel: "Vaihda salasana",
    changePasswordSuccessfulMessage: "Salasana vaihdettu onnistuneesti.",
    changePasswordFailedMessage: "Salasanan vaihto epäonnistui!",

    modalPromptAreYouSure: "Oletko varma?",
    modalResponseYes: "Kyllä",
    modalResponseNo: "Ei",

    userListGetListFailed: "Virhe haettaessa käyttäjäluetteloa!",
    userListButtonLabelResetPassword: "Nollaa salasana",
    userListButtonLabelResetPasswordInProgress: "Nollaaminen ...",
    userListResetPasswordFailed: "Virhe salasanan nollaamisessa!",
    userListResetPasswordSucceeded: "Salasanan palautus onnistui.",
    userListButtonLabelActivateUser: "Aktivoi",
    userListButtonLabelActivateUserInProgress: "Aktivoidaan...",
    userListActivateUserFailed: "Virhe aktivoitaessa käyttäjää!",
    userListActivateUserSucceeded: "Käyttäjän aktivointi onnistui.",
    userListButtonLabelDeactivateUser: "Deaktivoi",
    userListButtonLabelDeactivateUserInProgress: "Deaktivoidaan...",
    userListDeactivateUserFailed: "Virhe deaktivoitaessa käyttäjää!",
    userListDeactivateUserSucceeded: "Käyttäjän deaktivointi onnistui.",

    backgroundInformationTitle: "Perustietokaavakkeet",

    measurementsGridColumnLabelCode: "Potilas-ID",
    measurementsGridColumnLabelUniqueId: "Arkistointitunnus",
    measurementsGridColumnLabelStatus: "Tila",
    measurementsGridColumnLabelUploadTime: "Aineisto ladattu",
    measurementsGridColumnLabelInitiateTime: "Alustusaika",
    measurementsGridColumnLabelNurse: "Hoitaja",
    measurementsGridColumnLabelDeliveryTime: "Toimitusaika",
    measurementsGridColumnLabelType: "Tutkimuslaji",
    measurementsGridColumnLabelHospitalName: "Asiakas",
    measurementsGridColumnLabelDoctor: "Lääkäri",
    measurementsGridColumnLabelAction: "Tapahtuma",

    measurementsGridButtonLabelCopy: "Kopioi",
    measurementsGridButtonLabelAssign: "Aseta jonoon",
    measurementsGridButtonLabelUploadReport: "Lähetä lausunto",
    measurementsGridButtonLabelDownloadReport: "Lataa lausunto",
    measurementsGridButtonLabelOpenAnalysis: "Avaa",
    measurementsGridButtonLabelCloseAnalysis: "Hyväksy lausunto",
    measurementsGridButtonLabelCloseAnalysisInProgress: "Lähetetään...",
    measurementsGridButtonLabelReturnStudy: "Palauta lausuttavaksi",
    measurementsGridButtonLabelReturnStudyConfirm: "Vahvista palautus",
    measurementsGridButtonTitleDownloadReport: "Avaa lausunto",
    measurementsGridButtonTitleDownloadSecondaryReport: "Avaa EKG-liiteraportti",
    measurementsGridButtonTitleUploadMeasurement: "Lähetä rekisteröinti",
    measurementsGridButtonLabelStudyDetailsShow: "Näytä lisätiedot",
    measurementsGridButtonLabelStudyDetailsHide: "Piilota lisätiedot",
    measurementsGridButtonLabelRejectStudy: "Hylkää",
    measurementsGridButtonLabelRejectStudyInProgress: "Hylätään...",
    measurementsGridButtonTitlePrintOrder: "Tulosta...",

    measurementsGridTitleNewReport: "Uusi lausunto!",

    closeEcgAnalysisFailed: "Lähetys epäonnistui",
    rejectStudyFailed: "Hylkääminen epäonnistui!",

    reportUploadButtonConfirmLabel: "Lähetä",
    reportUploadButtonCancelLabel: "Peruuta",
    reportUploadButtonConfirmInProgressLabel: "Lähetetään...",

    reportUploadMeasurementStatusInvalid: "Rekisteröinnin tila virheellinen",
    reportUploadMeasurementFileNameInvalid: "Potilas-ID rekisteröinnissä ja lausunnossa eivät ole samat. Tarkista lausunto-pdf.",
    reportUploadFailed: "Lausunnon lähetys epäonnistui",

    itemInfoTextLabelHospitalName: "Asiakas",
    itemInfoTextLabelBmi: "BMI",
    itemInfoTextLabelEssScore: "ESS-score",

    uploadFormInvalidLabelRequired: "Pakollinen kenttä!",

    uploadFormOptionNo: "Ei",
    uploadFormOptionYes: "Kyllä",
    uploadFormOptionLittle: "Vähän",
    uploadFormOptionLot: "Paljon",
    uploadFormOptionSometimes: "Joskus",
    uploadFormOptionDaily: "Päivittäin",
    uploadFormOptionWell: "Hyvin",
    uploadFormOptionPoorly: "Huonosti",
    uploadFormOptionNotAtAll: "En ollenkaan",

    uploadFormInfoLabelHours: "h",
    uploadFormInfoLabelMinutes: "min",
    uploadFormInfoLabelTimes: "kertaa",

    uploadFormLabelCode: "Potilas-ID",
    uploadFormTypeFieldLabel: "Tutkimuslaji",
    uploadFormLabelReportLanguage: "Lausuntokieli",


    uploadFormValidationErrorDefault: "Lähetys ei onnistu, tarkista täyttämäsi tiedot!",
    uploadFormInvalidLabelCode: "Enintään 20 merkkiä. Ääkkösiä, erikoismerkkejä tai henkilötunnusta ei sallita.",

    uploadFormButtonLabel: "Lähetä",
    uploadFormButtonInProgressLabel: "Lähetetään lausuttavaksi, odota hetki...",

    uploadSucceeded: "Lähetys onnistui.",
    uploadFailed: "Lähetys epäonnistui.",
    uploadFailedCodeNotUnique: "Potilas-ID on jo käytetty, valitse uusi ID!",
    uploadFailedFileNameNotUnique: "Tiedosto on jo lähetetty, valitse oikea tiedosto!",
    uploadErrorCodeNotUnique: "Potilas-ID on jo käytetty, valitse uusi ID!",
    uploadErrorCannotGetLanguageSettings: "Käyttöpaikan lausuntokieliasetusten haku epäonnistui!",

    uploadFormMeasurementFileLabel: "Tiedosto",
    uploadFormInvalidLabelFile: "Tiedosto on ladattava!",

    sleepMeasurementFileInvalid: "Virheellinen tiedostomuoto tai tiedosto on liian pieni. Valitse .dtx-tiedosto.",
    ecgMeasurementFileInvalid: "Virheellinen tiedostomuoto tai tiedosto on liian pieni. Valitse kelvollinen .c3s-tiedosto.",
    bpMeasurementFileInvalid: "Virheellinen tiedostomuoto. Valitse kelvollinen .bpm-tiedosto.",

    uploadFileFailed: "Tiedoston lataus epäonnistui!",
    uploadFileButtonCancel: "Peruuta lataus",
    uploadFileButtonDelete: "Poista tiedosto",
    uploadFileButtonRetry: "Uudelleenyritys",

    enumMeasurementTypeSleep: "Yöpolygrafia",
    enumMeasurementTypeHolterEcg: "Holter-EKG",
    enumMeasurementTypeSymptomHolter: "Oire-Holter",
    enumMeasurementTypeEcgAtrialFibrillation: "Eteisvärinän seulonta",
    enumMeasurementTypeBloodPressure: "Verenpaineseuranta",

    holterTimedTypeOfDurationDays1: "Holter 24h",
    holterTimedTypeOfDurationDays2: "Holter 48h",
    holterTimedTypeOfDurationDays3: "Holter 72h",
    holterTimedTypeOfDurationDays4: "Holter 4d",
    holterTimedTypeOfDurationDays5: "Holter 5d",
    holterTimedTypeOfDurationDays6: "Holter 6d",
    holterTimedTypeOfDurationDays7: "Holter 7d",

    uploadFormLabelGender: "Sukupuoli",
    uploadFormLabelGenderF: "Nainen",
    uploadFormLabelGenderM: "Mies",
    uploadFormInvalidLabelGender: "Pakollinen kenttä!",

    uploadFormLabelAge: "Ikä",
    uploadFormInfoLabelAge: "v",
    uploadFormInvalidLabelAge: "Tarkista ikä!",

    uploadFormLabelHeight: "Pituus",
    uploadFormInfoLabelHeight: "cm",
    uploadFormInvalidLabelHeight: "Tarkista pituus!",

    uploadFormLabelWeight: "Paino",
    uploadFormInfoLabelWeight: "kg",
    uploadFormInvalidLabelWeight: "Tarkista paino!",

    uploadFormLabelWaistline: "Vyötärön ympärys",
    uploadFormInfoLabelWaistline: "cm",
    uploadFormInvalidLabelWaistline: "Tarkista vyötärön ympärys!",

    uploadFormLabelCholesterol: "Kolesteroli (LDL)",
    uploadFormInfoLabelCholesterol: "mmol/l",
    uploadFormInvalidLabelCholesterol: "Tarkista kolesteroli!",

    uploadFormLabelOverweight: "Ylipaino",

    uploadFormLabelProfession: "Ammatti",
    uploadFormInvalidLabelProfession: "Enintään 40 merkkiä!",

    uploadFormLabelHypertension: "Verenpainetauti",
    uploadFormLabelDiabetes: "Diabetes",
    uploadFormLabelOverbite: "Ylipurenta",
    uploadFormLabelCloggedNasalPassage: "Ahtautunut nenäkäytävä",
    uploadFormLabelAllergicRhinitis: "Allerginen nuha",
    uploadFormLabelSmallChin: "Pieni leuka",
    uploadFormLabelCoronaryArteryDisease: "Sepelvaltimotauti",
    uploadFormLabelCoronaryInsufficiency: "Sydämen vajaatoiminta",
    uploadFormLabelCerebrovascularDisorders: "Aivoverenkierron häiriöitä",
    uploadFormLabelKidneyDisease: "Munuaissairaus",

    uploadFormLabelSmoking: "Tupakointi",
    uploadFormLabelUseOfSleepingPills: "Unilääkkeiden käyttö",
    uploadFormLabelAlcoholConsumption: "Alkoholin käyttö (annoksia viikossa)",

    alcoholConsumptionOptionOver20: "yli 20",

    uploadFormHeaderFrequency: "Esiintyykö säännöllisesti...",
    uploadFormLabelMorningHeadaches: "Aamupäänsärkyä",
    uploadFormLabelBreathingLoss: "Hengityskatkoksia nukkuessa",
    uploadFormLabelFluttering: "Korahtelua nukkuessa",
    uploadFormLabelSnoring: "Kuorsausta",
    uploadFormLabelRestlessSleep: "Levotonta unta, herkkää heräilyä",
    uploadFormLabelDaytimeSleepiness: "Päiväväsymystä",
    uploadFormLabelDryMouthInMorning: "Suun kuivuutta aamulla",
    uploadFormLabelNeedToUrinate: "Virtsaamisen tarvetta nukkuessa",

    uploadFormOptionFrequencyDaily: "Päivittäin",
    uploadFormOptionFrequencyWeekly: "Viikoittain",
    uploadFormOptionFrequencyMonthly: "Kuukausittain",
    uploadFormOptionFrequencyRarely: "Ei esiinny",

    uploadFormHeaderProbability: "Todennäköisyys että nukahdan tai torkahdan, kun...",
    uploadFormLabelSittingReading: "Istun lukemassa",
    uploadFormLabelWatchingTelevision: "Katselen televisiota",
    uploadFormLabelSittingPassive: "Istun passiivisena",
    uploadFormLabelTravelInCarForAnHour: "Matkustan autossa tunnin ajan",
    uploadFormLabelLyingDownInAfternoon: "Lepään makuuasennossa iltapäivällä",
    uploadFormLabelSittingTalkingToSomeone: "Istun puhumassa jonkun kanssa",
    uploadFormLabelSitInPeaceAfterNonAlcoholicLunch: "Istun kaikessa rauhassa alkoholittoman lounaan jälkeen",
    uploadFormLabelSitInCarAfterItHasStoppedForFewMinutes: "Istun autossa sen pysähdyttyä muutamaksi minuutiksi",

    uploadFormOptionProbabilityZero: "Nolla",
    uploadFormOptionProbabilitySmall: "Pieni",
    uploadFormOptionProbabilityModerate: "Kohtalainen",
    uploadFormOptionProbabilityLarge: "Suuri",

    uploadFormLabelDiagnosedHeartDisease: "Todettu sydänsairaus ja/tai rytmihäiriö",

    uploadFormLabelCurrentMedication: "Käytössä oleva lääkitys",
    uploadFormLabelReasonForStudy: "Tutkimuksen aihe / lähete",

    uploadFormHeaderSleepRegistrationEvents: "Unirekisteröintiajan tapahtumat:",

    uploadFormLabelSleepQuality: "Nukuitteko paremmin vai huonommin kuin tavallisesti?",

    uploadFormOptionSleepQualityBetter: "Paremmin",
    uploadFormOptionSleepQualitySame: "Ei eroa",
    uploadFormOptionSleepQualityWorse: "Huonommin",

    uploadFormLabelFallAsleepDuration: "Kestikö nukahtamisenne ajallisesti tavanomaista…",

    uploadFormOptionFallingAsleepDurationShorter: "vähemmän",
    uploadFormOptionFallingAsleepDurationSame: "saman verran",
    uploadFormOptionFallingAsleepDurationLonger: "pidempään",

    uploadFormLabelSleepHours: "Montako tuntia nukuitte oman arvionne mukaan?",
    uploadFormLabelMinutesAwakeAfterMeasurementStart:
      "Montako minuuttia arvioitte olleenne hereillä rekisteröinnin käynnistymisen jälkeen?",
    uploadFormLabelAwakeningsDuringMeasurement: "Heräsittekö rekisteröinnin aikana?",

    uploadFormLabelSleepMeasurementNight: "Miten nukuit rekisteröintiyön?",

    uploadFormLabelGoToBedTime: "Mihin kellonaikaan menit nukkumaan?",
    uploadFormLabelWakeUpTime: "Mihin kellonaikaan heräsit aamulla?",
    uploadFormInvalidLabelGoToBedWakeUp: "Pakollinen kenttä! Nukkumaanmenoajan on oltava ennen herätysaikaa.",

    uploadFormLabelParentsHeartAttack60: "Onko jompikumpi vanhemmistasi sairastanut sydäninfarktin alle 60-vuotiaana?",
    uploadFormLabelParentsStroke75: "Onko jompikumpi vanhemmistasi sairastanut aivohalvauksen alle 75-vuotiaana?",

    uploadFormLabelSleepDiary: "Unipäiväkirja",
    uploadFormLabelPatientFeedback: "Muuta ilmoitettavaa",
    uploadFormLabelNurseComments: "Hoitajan kommentit / viesti lausuvalle lääkärille",

    uploadFormLabelDiary: "Oirepäiväkirja",
    uploadFormLabelAbnormalHeartbeats: "Muljahduksia",
    uploadFormLabelSlowPulse: "Tunnetta hitaasta pulssista",
    uploadFormLabelFastPulse: "Tunnetta tiheästä pulssista",
    uploadFormLabelPalpitation: "Sydämentykytystä",
    uploadFormLabelIrregularPulse: "Epätasaista sykettä",
    uploadFormLabelDizziness: "Huimausta",
    uploadFormLabelLossOfConsciousness: "Tajunnan hämärtymisiä",
    uploadFormLabelBlackouts: "Tajuttomuuskohtauksia",
    uploadFormLabelChestPain: "Rintakipua",
    uploadFormLabelShortnessOfBreath: "Hengenahdistus",

    uploadFormLabelSymptom: "Oiretuntemus",
    uploadFormLabelSymptoms: "Symptoms",
    uploadFormInvalidLabelEcgSymptoms:
      "Vähintään yhden oireen tiedot on täytettävä. Oirekuvaus voi olla enintään 60 merkkiä pitkä.",

    uploadFormLabelMeasurementStartTime: "Rekisteröinnin aloitusaika",

    measurementStatusUploaded: "Ladattu",
    measurementStatusReadyForAnalysis: "Valmis analyysiin",
    measurementStatusReadyForPostAnalysis: "Valmiina jälkianalyysiin",
    measurementStatusDone: "Valmis",
    measurementStatusRejectedByDataOfficer: "Hylätty",
    measurementStatusPreAnalysed: "Esianalysoitu",
    measurementStatusInitiated: "Alustettu",

    nurseMeasurementStatusProcessing: "Lausuttavana",
    nurseMeasurementStatusRejected: "Hylätty",
    nurseMeasurementStatusDone: "Valmis",

    newUsersLabelHospitalName: "Customer",
    newUsersLabelEmailAddresses: "Sähköpostiosoitteet",
    newUsersInvalidLabelEmailAddresses: "Sähköposti väärässä muodossa",
    newUsersInvalidLabelEmailAddressesLength: "Sähköpostiosoite on liian pitkä (enintään 50 merkkiä)",

    newUsersPlaceholderEmailAddresses: "yksi sähköposti per rivi",
    newUsersAddButtonLabel: "Lisää käyttäjiä",
    newUsersAddButtonLoadingLabel: "Lisätään ...",
    newUsersQuestionConfirmSubmit: "Haluatko lisätä nämä käyttäjät sairaalaan",
    newUsersAddFailedInvalidEmails: "Virheelliset sähköpostit",
    newUsersAddFailedExistingEmails: "Olemassa olevat sähköpostit",
    newUsersAddFailedUnsentEmails: "Sähköposteja ei voitu lähettää osoitteeseen",
    newUsersAddNursesFailedPost: "Virhe sairaanhoitajien lisäämisessä!",
    newUsersAddNursesSuccessfulPost: "Sairaanhoitajat lisätty onnistuneesti.",

    analysisMessageLoadBpDataFailed: "Virhe verenpainetietojen lataamisessa!",
    analysisMessageFailedGet: "Rekisteröinnin lataaminen epäonnistui",
    analysisButtonSave: "Välitallennus",
    analysisLabelSaveInProgress: "Suoritetaan välitallennusta...",
    analysisMessageSaveFailed: "Välitallennus epäonnistui",
    analysisMessageSaveSuccess: "Välitallennus onnistui",
    analysisLabelStartTime: "Rekisteröinnin aloitusaika",
    analysisLabelEcgSamplesDocument: "EKG-liiteraportti",
    analysisButtonPreviewReport: "Lausunnon esikatselu",
    finishAnalysisFailed: "Analysointi epäonnistui",
    analysisLabelPatientStatusComment: "Esitiedot",
    analysisLabelFindings: "Löydökset",
    analysisLabelConclusion: "Yhteenveto",
    analysisLabelDiagnosis: "Diagnoosi",
    analysisLabelButtonFinish: "Valmis",
    analysisLabelButtonFinishInProgress: "Lähetetään lausuntoa...",
    analysisLabelButtonFinishConfirm: "Vahvista valmis lausunto",
    analysisLabelButtonFinishCancel: "Peruuta",
    analysisFormValidationError: "Validointi epäonnistui, tarkista puuttuuko tietoja!",
    analysisFormInvalidLabelRequired: "Pakollinen kenttä!",
    analysisFormInvalidEcgFile: "Valitse kelvollinen PDF-tiedosto. Tiedostonimen on sisällettävä Potilas-ID.",
    analysisLabelExternalAnalysisUrl: "Cardiologs url: ",

    analysisBpChartLegendDiastolic: "Diastolinen",
    analysisBpChartLegendSystolic: "Systolinen",
    analysisBpChartLegendHeartRate: "Syke",

    bpFindingsActiveTime: "Aktiiviaika",
    bpFindingsSleepTime: "Uniaika",
    bpFindingsWholeDay: "Koko vuorokausi",
    bpFindingsAverageSystolicBP: "Syst.  keskiverenpaine",
    bpFindingsAverageDiastolicBP: "Diast. keskiverenpaine",
    bpFindingsAverageSystolicRange: "Syst. vaihteluväli",
    bpFindingsAverageDiastolicRange: "Diast. vaihteluväli",
    bpFindingsSystolicBPload: "Syst. verenpainekuorma",
    bpFindingsDiastolicBPload: "Diast. verenpainekuorma",
    bpFindingsAveragePuls: "Keskimääräinen syke",

    bpSmallerThanActive: "< aktiiviaikana",

    reportLabelPatient: "Potilas",
    reportPageLabel: "Sivu",
    reportPatientDataPageTitle: "Esitiedot",
    reportUniqueIdLabel: "Arkistointitunnus",
    reportFindingsSectionTitle: "Löydökset",

    bpReportBPmonitoring24h: "Verenpaineen vuorokausiseuranta",

    ecgReportSymptomHolterDisclaimer: "Oire-Holter on tutkimus, jonka analysointi keskittyy tutkittavan oiretuntemusten aikaiseen aineistoon. Tällöin analyysiohjelmiston automaattisesti havaitsemat löydökset esimerkiksi lisälyöntien määrän osalta eivät pidä tarkalleen paikkaansa.",
    ecgReportSymptomFindingsTitle: "Potilaan kirjaamat oiretuntemukset",
    ecgReportAtrialFibrillationTitle: "Eteisvärinän seulonta",
    ecgReportAtrialFibrillationDisclaimer: "Eteisvärinän seulonta on tutkimus, jonka analysointi keskittyy eteisvärinän todentamiseen tai poissulkemiseen. Tällöin analyysiohjelmiston automaattisesti havaitsemat löydökset esimerkiksi lisälyöntien määrän osalta eivät pidä tarkalleen paikkaansa.",
    ecgReportLabelSymptom: "Oire",

    downloadedReportFilenamePrefix_sleep: "yöpolygrafia",
    downloadedReportFilenamePrefix_ecgHolter: "holter",
    downloadedReportFilenamePrefix_symptomHolter: "oire-holter",
    downloadedReportFilenamePrefix_ecgAtrialFibrillation: "eteisvärinän-seulonta",
    downloadedReportFilenamePrefix_samples: "liiteraportti",
    downloadedReportFilenamePrefix_bloodPressure: "verenpaine",

    ecgDataOfficerFileshareFolderInvalid: "CubeDB-kansio puuttuu tai ei ole uniikki",
    assignDoctorFailed: "Lausujan valinta epäonnistui!",

    errors_default: "Odottamaton virhe",
    errors_downloadReportFailed: "Lataus epäonnistui",

    assignDoctorSelectorLabelCurrent: "nykyinen",

    timeFilterFromLabel: "Mistä:",
    timeFilterToLabel: "Mihin:",
    timeFilterSearchButton: "Hae",
    timeFilterErrorMessage: "Aloituspäivän pitää olla aikaisemmin kuin lopetuspäivä",
    timeFilterLoadingLabel: "Haku käynnissä...",

    measurementListCount: "Rivien lukumäärä:",
    measurementListAllColumnFilterOption: "Kaikki",
    measurementsGridColumnLabelDeviceSerialNumber: "Sarjanumero",
    invalidDeviceSerialNumber: ".c3s ja .edf tiedoston sarjanumerot eivät täsmää",

    changeLanguageLabel: "Vaihda kieli",
    englishLang: "🇬🇧 englanti",
    finnishLang: "🇫🇮 suomi",
    displayLanguageLabel: "Kielivalinta",
    changeLanguageFailedMessage: "Kielen vaihto epäonnistui",

    initiateMeasurementReasonForStudyInvalid: "Lähete tai tutkimuksen aihe on syötettävä",
    initiateFormButtonLabel: "Alusta",
    initiateFormButtonInProgressLabel: "Alustaa",
    initiateFailed: "Alustus epäonnistui: Server Error",

    reasonForStudyEmpty: "Lähete tai tutkimuksen aihe vaaditaan",
    invalidHospitalNameLength: "Sairaalan nimi on liian pitkä (enintään 300 merkkiä)",
    initiatedMeasurementFailedGet: "Virhe ladattaessa rekisteröintiä",

    orderFormLabelOperatorHospital: "Tutkimuspaikka",
    orderFormLabelOperatorHospitalInvalid: "Tutkimuspaikka on valittava",
    navigationOrderMeasurement: "Tilaa tutkimus",

    orderFormButtonLabel: "Tilaa",
    orderFormButtonInProgressLabel: "Tilataan...",
    orderFailed: "Tilaus epäonnistui: Server Error",
    operatorHospitalsFailedGet: "Tutkimuspaikkojen haku epäonnistui",

    printOrderFormLabelSsn: "Henkilötunnus",
    printOrderFormInvalidLabelSsn: "Virheellinen henkilötunnus",
    printOrderFormLabelName: "Nimi",
    printOrderFormInvalidLabelName: "Tutkittavan nimi vaaditaan",
    printOrderFormButtonLabel: "Tulosta",
    printOrderFormButtonInProgressLabel: "Tulostetaan..",
    printOrderFormValidationErrorDefault: "Syötetyt tiedot virheellisiä",
    printOrderFormLabelCode: "Potilas-ID",
    printOrderFormLabelInitiatedTime: "Tutkimuksen tilausaika",
    printOrderFormLabelOperatorHospital: "Tutkimuspaikka",
    printOrderFormLabelOrderingHospital: "Tilaaja",
    printOrderFormLabelUniqueId: "Arkistointitunnus",
    printOrderFormErrorGettingData: "Tapahtui virhe haettaessa dataa kaavakkeen tulostamista varten",
    printOrderFormType: "Tutkimus",

    orderingNurseCheckboxLabel: "Tilaava hoitaja",

    hospitalsTitle: "Käyttöpaikat",
    hospitalsFailedToGet: "Käyttöpaikkalistan avaaminen epäonnistui",

    editHospitalFailedToLoadHospitalDetails: "Käyttöpaikan tietojen lataaminen epäonnistui",
    editHospitalFailedToAddOperator: "Operaattorin lisääminen epäonnistui",
    editHospitalOperatorHospitals: "Operaattorit",
    editHospitalChooseOperatorText: " voi tilata tutkimuksia seuraavilta operaattoreilta: ",

    measurementsGridButtonTitlePrintForm: "Tulosta perustietokaavake",
    printOrderFormInvalidLabelType: "Virheellinen tutkimuslaji",
    printOrderFormInvalidLabelCode: "Virheellinen Potilas-ID",
    printOrderFormInvalidLabelUniqueId: "Virheellinen arkistointitunnus",
    printOrderFormInvalidLabelInitiatedTime: "Virheellinen alustusaika",
    printFormLabelLanguage: "Kieli",
    printFormInvalidLabelLanguage: "Virheellinen kieli",
    printFormButtonLabel: "Tulosta",
    swedishLang: "🇸🇪 ruotsi",
    russianLang: "🇷🇺 venäjä",
    printFormPatientTemplateNotFound: "Kaavaketta ei löytynyt",
    printFormFetchError: "Kaavakkeen haku epäonnistui",
    printOrderFormNoOperatorInformation: "Tutkimuspaikan tietoja eivät ole saatavilla.",
    measurementsGridTitleJobPending: "Odottaa toiminnon valmistumista",
    measurementsGridTitleJobFailed: "Toiminto epäonnistui, tarkista mitä tapahtui",
    measurementsGridTitleJobSucceeded: "Toiminto onnistui",

    eFormCodeInputErrorLabel: "Salasana ei ole kelvollinen",
    eFormCodeInputLabel: "Syötä salasana tähän:",
    eFormLandingPageTitle: "Kirjautuminen sähköiselle perustietokaavakkeelle",
    eFormLandingPageError: "Virheellinen salasana",
    eFormGetFormError: "Tietojen haku epäonnistui. Yritä myöhemmin uudelleen.",
    eFormUpdateFormError: "Tietojen tallennus epäonnistui. Yritä myöhemmin uudelleen.",

    eFormHeaderTitle: "Tervetuloa!",
    eFormHeaderText_paragraph1: "Kiitos kun täytät tämän kaavakkeen sähköisessä muodossa. Avullasi hoitohenkilökunnan aikaa vapautuu itse hoitotyöhön, jolloin autat samalla myös muita ihmisiä.",
    eFormHeaderText_paragraph2: "Täytä kaavake huolellisesti. Se auttaa tutkimuksen analysoivaa erikoislääkäriä ymmärtämään tilanteesi. Mikäli et tiedä vastausta johonkin kysymykseen, jätä kyseinen kohta tyhjäksi. Älä syötä kaavakkeelle mitään henkilö- tai yhteystietojasi. Muista palauttaa myös paperinen kaavake, vaikka et olisi sitä täyttänytkään.",
    eFormFooterTitle: "Kiitos!",
    eFormFooterText_paragraph1: "Vastauksesi on tallennettu automaattisesti. Tarkista vielä kerran, että olet vastannut kaikkiin kysymyksiin. Mikäli jonkin kohdan täyttäminen ei jostain syystä onnistunut, voit täydentää sen myös paperiselle kaavakkeelle. Palauta tutkimuslaite ja paperinen perustietokaavake saamiesi ohjeiden mukaisesti.",
    initiatedMeasurementEFormLanguageNotSaved: "Kaavakkeen kielivalintaa ei tallennettu",

    eFormSubmitButton: "Kirjaudu",
    textTooLongError: "Liian pitkä teksti",

    editHospitalFailedToUpdateHospitalMeasurementTypes: "Tutkimustyyppien valinta epäonnistui",
    editHospitalAllowedTypes: "Käytössä olevat tutkimukset",
    editHospitalAllowedTypesText: "voi alustaa ja purkaa seuraavia tutkimuksia:",
    editHospitalSubmitAllowedTypes: "Tallenna valitut tutkimukset",

    errorCannotGetHospitalTypes: "Virhe. Yritä myöhemmin uudelleen.",

    editHospitalSuccessfullyUpdatedMeasurementTypes: "Tutkimustyypit päivitetty onnistuneesti.",
    measurementsGridButtonLabelDeleteMeasurement: "Poista",
    measurementsGridButtonLabelDeleteMeasurementTooltip: "Poista alustettu rekisteröinti",
    measurementsGridButtonLabelDeleteMeasurementInProgress: "poistaminen...",
    deletingMeasurementFailed: "Rekisteröinnin poistaminen epäonnistui",
    measurementsGridButtonLabelShowComments: "Kommentit",
    modalResponseClose: "Sulje",

    commentAddingFailed: "Kommentin lisääminen epäonnistui",
    commentFetchingFailed: "Kommenttien noutaminen epäonnistui",

    errorCannotGetSummary: "Koontitiedoston haku epäonnistui",

    labelHospitalName: "Käyttöpaikan nimi",

    hospitalInformationUpdateError: "Käyttöpaikan tietojen päivitys epäonnistui",
    hospitalInformationUpdateSuccess: "Käyttöpaikan tietojen päivitys onnistui",
    hospitalInformationUpdateButton: "Tallenna",
    hospitalInformationPublicName: "Julkinen nimi",
    hospitalInformationPublicNameError: "Julkisen nimen pituus saa olla enintään 100 merkkiä",
    hospitalInformationTitle: "Käyttöpaikan tiedot",
    hospitalInformationAddress: "Osoite",
    hospitalInformationAddressError: "Osoitteen pituus saa olla enintään 1000 merkkiä",
    hospitalInformationCity: "Kaupunki",
    hospitalInformationCityError: "Kaupungin nimen pituus saa olla enintään 100 merkkiä",
    hospitalInformationOperatorAppointmentDescription: "Ajanvaraustiedot",
    hospitalInformationOperatorAppointmentDescriptionError: "Ajanvaraustietojen pituus saa olla enintään 1000 merkkiä",
    hospitalInformationPostalCode: "Postinumero",
    hospitalInformationPostalCodeError: "Postinumero saa olla enintään 100 merkkiä pitkä",

    hospitalPatientFormTemplatesTitle: "Valitse tutkimuskohtaisesti käytettävät perustietokaavakkeet",
    hospitalPatientFormTemplatesButton: "Vahvista",
    hospitalPatientFormTemplatesError: "Perustietokaavakkeiden valinta epäonnistui",
    hospitalPatientFormTemplatesSuccess: "Perustietokaavakkeiden valinta onnistui",

    editMeasurementDataButton: "Muokkaa",
    editMeasurementDataUpdateInProgressLabel: "Tallennetaan...",
    editMeasurementDataUpdateButtonLabel: "Tallenna",
    editMeasurementDataFormInvalid: "Syötetyt tiedot virheellisiä",
    editMeasurementDataUpdateFailureMessage: "Perustietojen tallentaminen epäonnistui",
    editMeasurementDataUpdateSuccessMessage: "Perustietojen tallentaminen onnistui",

    measurementGridAssignTechnicianSuccessful: "Jonoutettu teknikolle onnistuneesti",
    measurementGridAssignTechnicianError: "Jonoutus teknikolle epäonnistui",
    measurementsGridAssignTechnicianButton: "Valitse teknikko",
    measurementsGridAssignTechnicianInProgress: "Jonoutetaan...",
    measurementsGridColumnLabelTechnician: "Teknikko",
    measurementsGridButtonLabelAssignDoctor: "Valitse lääkäri",

    characterCounterCharactersRemaining: "merkkiä jäljellä",
    eformSymptomWarningLabel: "Enintään 60 merkkiä.",

    navigationLabelOldServiceModel: "Vanha toimintamalli",

    hospitalLanguageSettingsTitle: "Käyttöpaikan oletuslausuntokieli",
    hospitalLanguageSelect: "Kieli",
    hospitalReportLanguageToggleCheckbox: "Vahvista lausuntokieli",
    hospitalLanguageSettingsUpdateButton: "Päivitä lausuntokieliasetukset",
    hospitalLanguageSettingsUpdateFailure: "Lausuntokieliasetusten tallennus epäonnistui",
    hospitalLanguageSettingsUpdateSuccess: "Lausuntokieliasetusten tallennus onnistui",

    uploadGetContextFailed: "Purkukaavakkeen avaaminen epäonnistui. Yritä uudelleen.",

    measurementGridReportLanguage: "Lausunnon kieli",

    analysisPatientStatusCommentsGeneratorDoneButton: "Valmis",
    analysisPatientStatusCommentsRegenerateButton: "Luo uudelleen",

    generator_patientStatusComments_reasonForStudy_arrhythmiaSensations: "Rytmihäiriöoireet",
    generator_patientStatusComments_reasonForStudy_other: "Muu",
    generator_patientStatusComments_rhythmAffectingMedication_no: "Ei",
    generator_patientStatusComments_rhythmAffectingMedication_yes: "Kyllä",
    generator_patientStatusComments_symptomsDuringRecording_no: "Ei",
    generator_patientStatusComments_symptomsDuringRecording_yes: "Kyllä",
    generator_patientStatusComments_symptomsDuringRecording_noDiary: "Ei oirepäiväkirjaa",

    generator_label_patientStatusComments_reasonForStudy: "Tutkimuksen aihe",
    generator_label_patientStatusComments_rhythmAffectingMedication: "Rytmiin vaikuttava lääkitys",
    generator_label_patientStatusComments_symptomsDuringRecording: "Tutkimuksen aikaiset oireet",

    analysisPatientStatusCommentsConfirmRegenerateButton: "Luo uudelleen",
    analysisPatientStatusCommentsCancelRegenerateButton: "Peruuta",
    analysisRegenerationWarningLabel: "Työkaluun palaaminen poistaa lausuntotekstiin mahdollisesti tekemäsi muutokset!",

    generator_label_findings_prevailingRhythm: "Vallitseva rytmi",
    generator_label_findings_atrialBeats: "Eteislisälyönnit",
    generator_label_findings_atrialTachycardias: "Eteistakykardiat",
    generator_label_findings_avConduction: "AV-johtuminen",
    generator_label_findings_averageHr: "Keskisyke",
    generator_label_findings_commentAtrial: "Kommentti eteisarytmioihin",
    generator_label_findings_commentVentricular: "Kommentti kammioarytmioihin",
    generator_label_findings_deltaWave: "Delta-aalto",
    generator_label_findings_maximumHr: "Maksimisyke",
    generator_label_findings_minimumHr: "Minimisyke",
    generator_label_findings_pausesOver2_5s: "Pitkiä lyöntivälejä (> 2,5 s)",
    generator_label_findings_qrsWidth: "QRS-leveys",
    generator_label_findings_qtTime: "QT-aika",
    generator_label_findings_stSegment: "ST-alue",
    generator_label_findings_symptomDiaryComment: "Tutkimusajan oireiden kommentti",
    generator_label_findings_ventricularBeats: "Kammiolisälyönnit",
    generator_button_findings_getExternalAnalysis: "Tuo EKG-data",

    generator_findings_option_normal: "N",
    generator_findings_option_other: "Muu",

    uploadFileFailedFileAlreadyUploaded: "Tämä tiedosto on jo aikaisemmin lähetetty \${uploadTime} Potilas-ID:llä \${measurementCode} käyttäjän \${uploadingNurse} toimesta.",

    reportUploadFileNotForThisMeasurement: "Lausunnon ja tutkimuksen tunnistetiedot eivät täsmää!",

    analysisDataOutdatedErrorLabel: "EKG-datan parametreja on muutettu analyysiohjelmistossa, eivätkä ne vastaa lausunnon lukuja. Tuo EKG-data uudestaan.",

    generator_label_findings_ventricularTachycardias: "Kammiotakykardiat",
    externalFindingsMissingFieldsWarningLabel: "Some external findings missing, some sentences may not be generated",

    dataOfficerMeasurementStatusDeleted: "Poistettu",
    measurementsGridButtonLabelRestoreMeasurement: "Palauta",
    measurementsGridButtonLabelRestoreMeasurementInProgress: "Palautetaan",
    restoreMeasurementFailed: "Rekisteröinnin palauttaminen epäonnistui",
    restoreMeasurementSucceeded: "Rekisteröinnin palauttaminen onnistui",
    confirmDeleteMeasurement: "Oletko varma, että haluat poistaa alustetun tutkimuksen ${code}? Kyseistä tunnistetta ei voi enää käyttää uudelleen.",

    editHolterTypeButton: "Muokkaa Holter-tyyppiä",
    setHolterTypeButton: "Aseta Holter-tyyppi",

    measurementsGridButtonLabelReassignDoctor: "Lääkärin vaihto",
    reassignMeasurementSucceeded: "Lääkärin vaihto onnistui",
    reassignMeasurementFailed: "Lääkärin vaihto epäonnistui",
    assignFailedDoctorDoesNotHaveAccessToExternalAnalysis: "Valitulla lääkärillä ei ole kansiota Cardiologsissa",
    confirmReassignDoctor: "Haluatko varmasti vaihtaa lääkärin?",

    hospitalPutHospitalInChainButton: "Määritä ketju",
    hospitalPutHospitalInChainTitle: "Liitä sairaala ketjuun",
    hospitalPutHospitalInChainSuccess: "Liitetty ketjuun onnistuneesti",
    hospitalPutHospitalInChainFailure: "Ketjuun liittäminen epäonnistui",
    hospitalPutHospitalInChainSelect: "Sairaalaketju",

    eFormLandingPageTitle_304: "Perustietokaavake.fi",
    eFormLandingPageGreeting: "Tervetuloa!",
    eFormLandingPageDescription: "on anonyymi oirepäiväkirja Internetissä. Henkilötietojasi ei kysytä tai tallenneta missään vaiheessa. Kirjaa saamasi salasana viereiseen kenttään.",
    eFormLandingPageThankYou: "Kiitos osallistumisestasi!",

    measurementDetailsFileNameLabel: "Tiedoston nimi",
    measurementDetailsDataSizeLabel: "Tiedoston koko",

    homeHolterLandingPageTitle: "Koti-Holter.fi",
    homeHolterLandingPageDescription: "on Internetissä toimiva terveydenhuollon palvelukanava, joka on niin yksityisen kuin julkisenkin sektorin asiakkaiden käytettävissä. Kirjaudu sisään syöttämällä saamasi salasana viereiseen kenttään.",
    homeHolterLandingPageCustomerService: "Asiakaspalvelu",

    hospitalNurseSettingsCombinedReportCheckbox: "Combine reports for nurses",
    hospitalNurseSettingsUpdateButton: "Aktivoi 2FA",
    hospitalNurseSettings2FaCheckbox: "Aktivoi 2FA tämän sairaalan hoitajille",
    hospitalNurseSettingsTitle: "2FA hoitajille",
    hospitalNurseSettingsUpdateError: "2FA:n aktivointi epäonnistui",
    hospitalNurseSettingsUpdateSuccess: "2FA:n aktivointi onnistui",

    externalAnalysisUrlLabel: "Linkki EKG-dataan",

    measurementTypeLabelHomeHolter: "Koti-Holter",
    measurementTypeLabelHomeHolter24h: "Koti-Holter 24h",
    measurementTypeLabelHomeHolter48h: "Koti-Holter 48h",
    measurementTypeLabelHomeHolter7d: "Koti-Holter 7d",

    measurementDetailsSpecifiedHomeHolterDurationLabel: "Rekisteröinnin kesto",

    jobAlreadyExistsMessage: "Mittaus vielä käsittelyssä. Odota tai ota yhteyttä tukeen.",

    editHospitalAllowedTypesHeaderNonHome: "Terveysasema",
    editHospitalAllowedTypesHeaderHome: "Koti",

    homeHolterDuration1dTypeLabel: "Koti-Holter 24h",
    homeHolterDuration2dTypeLabel: "Koti-Holter 48h",
    homeHolterDuration3dTypeLabel: "Koti-Holter 72h",
    homeHolterDuration4dTypeLabel: "Koti-Holter 4d",
    homeHolterDuration5dTypeLabel: "Koti-Holter 5d",
    homeHolterDuration6dTypeLabel: "Koti-Holter 6d",
    homeHolterDuration7dTypeLabel: "Koti-Holter 7d",
    homeHolterTypeLabel: "Koti-Holter",
    homeSleepTypeLabel: "Kotiyöpolygrafia",
    homeSymptomHolterTypeLabel: "Oire-Holter kotiin",
    homeEcgAtrialFibrillationTypeLabel: "Eteisvärinän seulonta kotiin",
    homeBloodPressureTypeLabel: "Verenpaineseuranta kotiin",

    finishAnalysisFailedBecauseSamplesDocumentMissing: "EKG-raportti on vielä luomatta analyysiohjelmistossa!",

    mailingInfoFormNameLabel: "Nimi",
    mailingInfoFormNameInvalidLabel: "Virheellinen nimi",
    mailingInfoFormStreetAddressLabel: "Postiosoite",
    mailingInfoFormStreetAddressInvalidLabel: "Virheellinen postiosoite",
    mailingInfoFormEmailAddressLabel: "Sähköpostiosoite",
    mailingInfoFormPhoneNumberLabel: "Matkapuhelinnumero",
    mailingInfoFormPhoneNumberInvalidLabel: "Virheellinen matkapuhelinnumero",
    mailingInfoFormPermissionLabel: "Suostun henkilö- ja osoitetietojeni tallentamiseen tutkimuslaitteen postitusta varten",
    mailingInfoFormOrderButtonLabel: "Tilaa tutkimuslaite",
    mailingInfoFormDeviceOrderThankYouMessage: "Kiitos tilauksestasi!",
    mailingInfoFormDeviceOrderFailedErrorMessage: "Tilaus epäonnistui. Ole hyvä ja yritä uudelleen.",

    eFormWaitForDeviceMessage: "Odotetaan tutkimuslaitteen saapumista.",

    dataOfficerFolderHasNotFinishedSyncing: "Rekisteröintikansion synkronointi OneDriveen ei ole vielä valmis.",

    measurementsGridColumnLabelMailingInfoSetAt: "Postitustiedot asetettu",
    measurementsGridColumnLabelHomeStatus: "Tila",

    homeMeasurementStatusLabelWaitingForMailingInfo: "Odottaa laitetilausta",
    homeMeasurementStatusLabelMailingToPatient: "Odottaa laitetoimitusta",
    navigationOrderHomeMeasurement: "Tilaa kotitutkimus",
    nurseMeasurementStatusOrdered: "Tilattu",

    measurementsGridButtonLabelMailDevice: "Laite toimitettu",
    mailingConfirmationButtonLabel: "Vahvista laitteen toimitus",
    mailingConfirmationButtonInProgressLabel: "Vahvistus käynnissä",
    mailingDeviceConfirmationSuccess: "Laitteen toimitus vahvistettu",
    mailingDeviceConfirmationFailure: "Laitteen toimituksen vahvistus epäonnistui",

    mailDevicePageLabelDeviceSerialNumber: "Sarjanumero:",
    mailDevicePageDeviceSerialNumberError: "Sarjanumero ei kelpaa tai on jo käytössä",
    eFormHomeHeaderTitle: "Koti-Holter-palvelun otsikko",
    eFormHomeHeaderText_paragraph1: "Koti-Holter-palvelun ohjeteksti",
    eFormHomeHeaderText_paragraph2: "",
    mailingConfirmationPrintMailingInfoButtonLabel: "Kopioi postitustiedot",
    mailingConfirmationPrintingMailingInfoSuccess: "Postitustiedot kopioitu onnistuneesti leikepöydälle!",
    mailingConfirmationPrintingMailingInfoFailure: "Postitustietojen kopiointi epäonnistui!",
    mailingInfoFormPostalCodeLabel: "Postinumero",
    mailingInfoFormPostalCodeInvalidLabel: "Virheellinen postinumero",
    mailingInfoFormPostalAreaLabel: "Postitoimipaikka",
    mailingInfoFormPostalAreaInvalidLabel: "Virheellinen postitoimipaikka",

    measurementsGridColumnLabelDeviceMailedAt: "Laite toimitettu",

  })
  .set(LanguageCode.SV_SV,
    {
      navigationLogoDescription: "STP Medical Logo",

      loadingLabel: "Laddar...",

      footerCustomerSupportLabel: "Kundsupport",
      footerCustomerSupportPhoneLabel: "tel.",

      navigationList: "Huvudsida",
      navigationUpload: "Uppladdning",
      navigationActionsLog: "Aktivitetslogg",
      navigationUserList: "Användare",
      navigationAddUsers: "Tillägg sköterska",
      navigationLogout: "Logga ut",
      navigationBackgroundInformation: "Bakgrundsformulär",
      navigationInitiateMeasurement: "Initiera undersökningen",
      navigationHospitals: "Kliniker",

      authGuardForbidden: "Du har inte behörigheter för denna resurs.",
      authTokenExpired: "Din session har gått ut. Vänligen logga in igen.",
      authAccessDenied: "Du har inte behörigheter för denna funktion.",
      authAuthenticateFailed: "Det uppstod ett problem med autentiseringen. Vänligen logga in igen.",

      cellRendererCopyToClipboard: "Kopiera till Urklipp",

      loginFailed: "Inloggningen misslyckades",
      loginFailedInvalidCredentials: "Felaktigt användarnamn och/eller lösenord",
      loginFormLabelUsername: "Användarnamn",
      loginFormLabelPassword: "Lösenord",
      loginFormUsernameInvalidLabel: "Användarnamn krävs (högst 50 tecken)",
      loginFormPasswordInvalidLabel: "Lösenord krävs (högst 50 tecken)",
      loginFormButtonLoginLabel: "Logga in",
      loginFormButtonLoginInProgressLabel: "Loggar in...",

      loginFormButtonOtpLoginLabel: "Bekräfta",
      loginFormLabelOtp: "Säkerhetskod",
      loginFormOtpInvalidLabel: "Säkerhetskod krävs",
      loginFormButtonOtpLoginInProgressLabel: "Kontrollerar säkerhetskoden...",
      loginFormOtpTitleLabel: "Säkerhetskoden har skickats till din e-postadress",
      loginFormInvalidOtp: "Felaktig säkerhetskod",

      changePasswordOldPasswordLabel: "Gamla lösenordet",
      changePasswordNewPasswordLabel: "Nya lösenordet",
      changePasswordRetypePasswordLabel: "Skriv lösenordet igen",
      changePasswordStrongPasswordMessage:
        "8-20 tecken, inga mellanslag, ett nummer, en versal, en gemena, ett specialtecke, t.ex. ! @ #? ]",
      changePasswordLabel: "Ändra lösenordet",
      changePasswordSuccessfulMessage: "Lösenordet har ändrats.",
      changePasswordFailedMessage: "Det gick inte att ändra lösenordet!",

      modalPromptAreYouSure: "Är du säker?",
      modalResponseYes: "Ja",
      modalResponseNo: "Nej",

      userListGetListFailed: "Fel i hämtande av användarlistan!",
      userListButtonLabelResetPassword: "Återställ lösenordet",
      userListButtonLabelResetPasswordInProgress: "Återställer...",
      userListResetPasswordFailed: "Fel i återställande av lösenordet!",
      userListResetPasswordSucceeded: "Lösenordsåterställningen lyckades.",
      userListButtonLabelActivateUser: "Aktivera",
      userListButtonLabelActivateUserInProgress: "Aktiveras...",
      userListActivateUserFailed: "Fel i aktiverandet av användaren!",
      userListActivateUserSucceeded: "Användaraktiverandet lyckades.",
      userListButtonLabelDeactivateUser: "Deaktivera",
      userListButtonLabelDeactivateUserInProgress: "Deaktiveras...",
      userListDeactivateUserFailed: "Fel i deaktiverandet av användaren!",
      userListDeactivateUserSucceeded: "Deaktiverandet av användaren lyckades.",

      backgroundInformationTitle: "Bakgrundsformulärer",

      measurementsGridColumnLabelCode: "Patient-ID",
      measurementsGridColumnLabelUniqueId: "Arkiveringreferens",
      measurementsGridColumnLabelStatus: "Status",
      measurementsGridColumnLabelUploadTime: "Uppladdningstid",
      measurementsGridColumnLabelInitiateTime: "Initieringstid",
      measurementsGridColumnLabelNurse: "Sköterska",
      measurementsGridColumnLabelDeliveryTime: "Leveranstid",
      measurementsGridColumnLabelType: "Undersökningstyp",
      measurementsGridColumnLabelHospitalName: "Kund",
      measurementsGridColumnLabelDoctor: "Läkare",
      measurementsGridColumnLabelAction: "Händelse",

      measurementsGridButtonLabelCopy: "Kopiera",
      measurementsGridButtonLabelAssign: "Tilldela till läkare",
      measurementsGridButtonLabelUploadReport: "Skicka utlåtandet",
      measurementsGridButtonLabelDownloadReport: "Nedladda utlåtandet",
      measurementsGridButtonLabelOpenAnalysis: "Öppna",
      measurementsGridButtonLabelCloseAnalysis: "Acceptera uttalandet",
      measurementsGridButtonLabelCloseAnalysisInProgress: "Skickas...",
      measurementsGridButtonLabelReturnStudy: "Skicka tillbaka för utlåtande",
      measurementsGridButtonLabelReturnStudyConfirm: "Bekräfta returen",
      measurementsGridButtonTitleDownloadReport: "Öppna utlåtandet",
      measurementsGridButtonTitleDownloadSecondaryReport: "Öppna EKG-rapportbilaga",
      measurementsGridButtonTitleUploadMeasurement: "Uppladda registreringen",
      measurementsGridButtonLabelStudyDetailsShow: "Visa tilläggsuppgifter",
      measurementsGridButtonLabelStudyDetailsHide: "Dölja tilläggsuppgifter",
      measurementsGridButtonLabelRejectStudy: "Avvisa",
      measurementsGridButtonLabelRejectStudyInProgress: "Avvisas...",
      measurementsGridButtonTitlePrintOrder: "Skriv ut...",

      measurementsGridTitleNewReport: "Ett nytt utlåtande!",

      closeEcgAnalysisFailed: "Överföringen misslyckades",
      rejectStudyFailed: "Avvisandet misslyckades!",

      reportUploadButtonConfirmLabel: "Skicka",
      reportUploadButtonCancelLabel: "Avbryta",
      reportUploadButtonConfirmInProgressLabel: "Skickas...",

      reportUploadMeasurementStatusInvalid: "Felaktig status på registreringen",
      reportUploadMeasurementFileNameInvalid: "Patient-ID i registreringen och utlåtandet är inte detsamma. Kontrollera utlåtande-pdf.",
      reportUploadFailed: "Sändningen på utlåtandet misslyckades",

      itemInfoTextLabelHospitalName: "Kund",
      itemInfoTextLabelBmi: "BMI",
      itemInfoTextLabelEssScore: "ESS-score",

      uploadFormInvalidLabelRequired: "Obligatorisk fält!",

      uploadFormOptionNo: "Nej",
      uploadFormOptionYes: "Ja",
      uploadFormOptionLittle: "Lite",
      uploadFormOptionLot: "Mycket",
      uploadFormOptionSometimes: "Ibland",
      uploadFormOptionDaily: "Dagligen",
      uploadFormOptionWell: "Bra",
      uploadFormOptionPoorly: "Dåligt",
      uploadFormOptionNotAtAll: "Inte alls",

      uploadFormInfoLabelHours: "h",
      uploadFormInfoLabelMinutes: "min",
      uploadFormInfoLabelTimes: "gånger",

      uploadFormLabelCode: "Patient-ID",
      uploadFormTypeFieldLabel: "Undersökningstyp",
      uploadFormLabelReportLanguage: "Utlåtandespråket",

      uploadFormValidationErrorDefault: "Det går inte att skicka, kontrollera uppgifterna du har fyllt i!",
      uploadFormInvalidLabelCode: "Högst 20 tecken. Diakritiska, specialtecken eller personnummer är inte tillåtna.",

      uploadFormButtonLabel: "Skicka",
      uploadFormButtonInProgressLabel: "Skickas för utlåtande, vänligen vänta...",

      uploadSucceeded: "Överföringen lyckades.",
      uploadFailed: "Överföringen mislyckades.",
      uploadFailedCodeNotUnique: "Patient-ID är redan i bruk, välj en annan ID!",
      uploadFailedFileNameNotUnique: "Filen har redan skickats, välj den rätta filen!",
      uploadErrorCodeNotUnique: "Patient-ID är redan i bruk, välj en annan ID!!",
      uploadErrorCannotGetLanguageSettings: "Det gick inte att hämta användningsplatsens inställningar för utlåtandespråket!",

      uploadFormMeasurementFileLabel: "Fil",
      uploadFormInvalidLabelFile: "Filen måste uppladdas!",

      sleepMeasurementFileInvalid: "Felaktig filform eller filen är för liten. Välj en giltig .dtx fil.",
      ecgMeasurementFileInvalid: "Felaktig filform eller filen är för liten. Välj en giltig .c3s fil.",
      bpMeasurementFileInvalid: "Felaktig filform. Välj en giltig .bpm fil.",

      uploadFileFailed: "Det gick inte att uppladda filen!",
      uploadFileButtonCancel: "Avbryt uppladdningen",
      uploadFileButtonDelete: "Radera filen",
      uploadFileButtonRetry: "Försök igen",

      enumMeasurementTypeSleep: "Nattpolygrafi",
      enumMeasurementTypeHolterEcg: "Holter-EKG",
      enumMeasurementTypeSymptomHolter: "Symtom-Holter",
      enumMeasurementTypeEcgAtrialFibrillation: "Screening för förmaksflimmer",
      enumMeasurementTypeBloodPressure: "Blodtryck",

      holterTimedTypeOfDurationDays1: "Holter 24h",
      holterTimedTypeOfDurationDays2: "Holter 48h",
      holterTimedTypeOfDurationDays3: "Holter 72h",
      holterTimedTypeOfDurationDays4: "Holter 4d",
      holterTimedTypeOfDurationDays5: "Holter 5d",
      holterTimedTypeOfDurationDays6: "Holter 6d",
      holterTimedTypeOfDurationDays7: "Holter 7d",

      uploadFormLabelGender: "Kön",
      uploadFormLabelGenderF: "Kvinna",
      uploadFormLabelGenderM: "Man",
      uploadFormInvalidLabelGender: "Obligatorisk fält!",

      uploadFormLabelAge: "Ålder",
      uploadFormInfoLabelAge: "år",
      uploadFormInvalidLabelAge: "Kontrollera ålder!",

      uploadFormLabelHeight: "Längd",
      uploadFormInfoLabelHeight: "cm",
      uploadFormInvalidLabelHeight: "Kontrollera längd!",

      uploadFormLabelWeight: "Vikt",
      uploadFormInfoLabelWeight: "kg",
      uploadFormInvalidLabelWeight: "Kontrollera vikt!",

      uploadFormLabelWaistline: "Midjemått",
      uploadFormInfoLabelWaistline: "cm",
      uploadFormInvalidLabelWaistline: "Kontrollera midjemått!",

      uploadFormLabelCholesterol: "Kolesterol (LDL)",
      uploadFormInfoLabelCholesterol: "mmol/l",
      uploadFormInvalidLabelCholesterol: "Kontrollera kolesterol!",

      uploadFormLabelOverweight: "Övervikt",

      uploadFormLabelProfession: "Yrke",
      uploadFormInvalidLabelProfession: "Högst 40 tecken!",

      uploadFormLabelHypertension: "Hypertoni",
      uploadFormLabelDiabetes: "Diabetes",
      uploadFormLabelOverbite: "Överbett",
      uploadFormLabelCloggedNasalPassage: "Förträngning i näspassagen",
      uploadFormLabelAllergicRhinitis: "Allergisk rinit",
      uploadFormLabelSmallChin: "Liten haka",
      uploadFormLabelCoronaryArteryDisease: "Kranskärlssjukdom",
      uploadFormLabelCoronaryInsufficiency: "Hjärtsvikt",
      uploadFormLabelCerebrovascularDisorders: "Cerebrovaskulära sjukdomar",
      uploadFormLabelKidneyDisease: "Njursjukdom",

      uploadFormLabelSmoking: "Rökning",
      uploadFormLabelUseOfSleepingPills: "Användning av sömnmedel",
      uploadFormLabelAlcoholConsumption: "Alkoholkonsumption (doser per vecka)",

      alcoholConsumptionOptionOver20: "över 20",

      uploadFormHeaderFrequency: "Uppkommer det... (regelbundet)",
      uploadFormLabelMorningHeadaches: "Huvudvärk på morgonen",
      uploadFormLabelBreathingLoss: "Andningspauser",
      uploadFormLabelFluttering: "Rossling under natten",
      uploadFormLabelSnoring: "Snarkning",
      uploadFormLabelRestlessSleep: "Orolig sömn, känsligt uppvaknande",
      uploadFormLabelDaytimeSleepiness: "Trötthet på dagen",
      uploadFormLabelDryMouthInMorning: "Muntorrhet på morgonen",
      uploadFormLabelNeedToUrinate: "Behov att urinera under natten",

      uploadFormOptionFrequencyDaily: "Dagligen",
      uploadFormOptionFrequencyWeekly: "Veckovis",
      uploadFormOptionFrequencyMonthly: "Månadsvis",
      uploadFormOptionFrequencyRarely: "Uppkommer ej",

      uploadFormHeaderProbability: "Hur stor är risken att du slumrar till när du…",
      uploadFormLabelSittingReading: "Sitter och läser",
      uploadFormLabelWatchingTelevision: "Ser på TV",
      uploadFormLabelSittingPassive: "Sitter passivt",
      uploadFormLabelTravelInCarForAnHour: "Är passagerare på en timmes biltur utan stopp",
      uploadFormLabelLyingDownInAfternoon: "Ligger och slappnar av på eftermiddagen",
      uploadFormLabelSittingTalkingToSomeone: "Sitter och pratar med någon",
      uploadFormLabelSitInPeaceAfterNonAlcoholicLunch: "Sitter stilla efter en lunch utan alkohol",
      uploadFormLabelSitInCarAfterItHasStoppedForFewMinutes: "Sitter i en bil efter att den har stannat i några minuter",

      uploadFormOptionProbabilityZero: "Ingen",
      uploadFormOptionProbabilitySmall: "Liten",
      uploadFormOptionProbabilityModerate: "Måttlig",
      uploadFormOptionProbabilityLarge: "Stor",

      uploadFormLabelDiagnosedHeartDisease: "Diagnostiserad hjärtsjukdom och/eller arytmi",

      uploadFormLabelCurrentMedication: "Nuvarande medicinering",
      uploadFormLabelReasonForStudy: "Ämnet för undersökningen/remiss",

      uploadFormHeaderSleepRegistrationEvents: "Händelser under sömnregistreringen:",

      uploadFormLabelSleepQuality: "Sov du bättre eller sämre än vanligt?",

      uploadFormOptionSleepQualityBetter: "Bättre",
      uploadFormOptionSleepQualitySame: "Ingen skillnad",
      uploadFormOptionSleepQualityWorse: "Sämre",

      uploadFormLabelFallAsleepDuration: "Jämfört med normalt, skedde insomnandet på...",

      uploadFormOptionFallingAsleepDurationShorter: "Kortare tid",
      uploadFormOptionFallingAsleepDurationSame: "Lika lång tid",
      uploadFormOptionFallingAsleepDurationLonger: "Längre tid",

      uploadFormLabelSleepHours: "Hur många timmar sov du enligt din egen bedömning?",
      uploadFormLabelMinutesAwakeAfterMeasurementStart:
        "Hur många minuter uppskattar du att du var vaken efter att registreringen startade?",
      uploadFormLabelAwakeningsDuringMeasurement: "Vaknade du upp under registreringen?",

      uploadFormLabelSleepMeasurementNight: "Hur sov du under registreringen?",

      uploadFormLabelGoToBedTime: "När gick du till sängs?",
      uploadFormLabelWakeUpTime: "Vilken tid vaknade du i morse?",
      uploadFormInvalidLabelGoToBedWakeUp: "Obligatorisk fält! Tiden du gick till sängs måste vara före tiden du vaknade.",

      uploadFormLabelParentsHeartAttack60: "Har någon av dina föräldrar haft en hjärtattack före 60 års ålder?",
      uploadFormLabelParentsStroke75: "Har någon av dina föräldrar haft en stroke före 75 års ålder?",

      uploadFormLabelSleepDiary: "Sömndagbok",
      uploadFormLabelPatientFeedback: "Annat att meddela/feedback till utredande läkare",
      uploadFormLabelNurseComments: "Sköterskans kommentarer/meddelande till utlåtande läkare",

      uploadFormLabelDiary: "Symtomdagbok",
      uploadFormLabelAbnormalHeartbeats: "Extraslag",
      uploadFormLabelSlowPulse: "Känsla av långsam puls",
      uploadFormLabelFastPulse: "Känsla av snabb puls",
      uploadFormLabelPalpitation: "Hjärtklappning",
      uploadFormLabelIrregularPulse: "Ojämn puls",
      uploadFormLabelDizziness: "Yrsel",
      uploadFormLabelLossOfConsciousness: "Minskad medvetenhet",
      uploadFormLabelBlackouts: "Medvetslöshet",
      uploadFormLabelChestPain: "Bröstsmärta",
      uploadFormLabelShortnessOfBreath: "Andnöd",

      uploadFormLabelSymptom: "Symtom",
      uploadFormLabelSymptoms: "Symtom",
      uploadFormInvalidLabelEcgSymptoms: "Uppgifterna för minst ett symtom måste fyllas i. Symtombeskrivningen kan vara max 60 tecken lång.",

      uploadFormLabelMeasurementStartTime: "Registreringens starttid",

      measurementStatusUploaded: "Uppladdad",
      measurementStatusReadyForAnalysis: "Färdig för analys",
      measurementStatusReadyForPostAnalysis: "Färdig för efteranalys",
      measurementStatusDone: "Färdig",
      measurementStatusRejectedByDataOfficer: "Avvisad",
      measurementStatusPreAnalysed: "Föranalyserad",
      measurementStatusInitiated: "Initierad",

      nurseMeasurementStatusProcessing: "Anhängig",
      nurseMeasurementStatusRejected: "Avvisad",
      nurseMeasurementStatusDone: "Färdig",

      newUsersLabelHospitalName: "Kund",
      newUsersLabelEmailAddresses: "Mejladresser",
      newUsersInvalidLabelEmailAddresses: "Mejladress i fel form!",
      newUsersInvalidLabelEmailAddressesLength: "Mejladress är för lång (högst 50 tecken)",

      newUsersPlaceholderEmailAddresses: "en mejladress per rad",
      newUsersAddButtonLabel: "Tillägg användare",
      newUsersAddButtonLoadingLabel: "Tillläggs...",
      newUsersQuestionConfirmSubmit: "Vill du lägga till dessa användare till sjukhuset",
      newUsersAddFailedInvalidEmails: "Felaktiga mejladdresser",
      newUsersAddFailedExistingEmails: "Nuvarande mejladresser",
      newUsersAddFailedUnsentEmails: "Meddelanden kunde inte skickas till adressen",
      newUsersAddNursesFailedPost: "Det gick inte att lägga till sjuksköterskor!",
      newUsersAddNursesSuccessfulPost: "Sjuksköterskor har lagts till.",

      analysisMessageLoadBpDataFailed: "Det gick inte att ladda blodtrycksuppgifter!",
      analysisMessageFailedGet: "Det gick inte att ladda registreringen",
      analysisButtonSave: "Spara",
      analysisLabelSaveInProgress: "Sparar...",
      analysisMessageSaveFailed: "Det gick inte att spara",
      analysisMessageSaveSuccess: "Sparandet lyckades",
      analysisLabelStartTime: "Starttid på registreringen",
      analysisLabelEcgSamplesDocument: "EKG-rapportbilaga",
      analysisButtonPreviewReport: "Förhandsvisning på utlåtandet.",
      finishAnalysisFailed: "Analysen misslyckades",
      analysisLabelPatientStatusComment: "Grunduppgifter",
      analysisLabelFindings: "Fynd",
      analysisLabelConclusion: "Sammandrag",
      analysisLabelDiagnosis: "Diagnos",
      analysisLabelButtonFinish: "Färdig",
      analysisLabelButtonFinishInProgress: "Utlåtandet skickas...",
      analysisLabelButtonFinishConfirm: "Bekräfta det färdiga utlåtandet",
      analysisLabelButtonFinishCancel: "Avbryt",
      analysisFormValidationError: "Det gick inte att validera, kontrollera att om uppgifter saknas!",
      analysisFormInvalidLabelRequired: "Obligatorisk fält!",
      analysisFormInvalidEcgFile: "Välj en giltid PDF-fil. Filnamnet måste innehålla Patient-ID.",
      analysisLabelExternalAnalysisUrl: "Cardiologs url: ",

      analysisBpChartLegendDiastolic: "Diastolisk",
      analysisBpChartLegendSystolic: "Systolisk",
      analysisBpChartLegendHeartRate: "Hjärtfrekvens",

      bpFindingsActiveTime: "Aktiv tid",
      bpFindingsSleepTime: "Sömntid",
      bpFindingsWholeDay: "Hela dygnet",
      bpFindingsAverageSystolicBP: "Genomsnittligt systoliskt blodtryck",
      bpFindingsAverageDiastolicBP: "Genomsnittligt diastoliskt blodtryck",
      bpFindingsAverageSystolicRange: "Systolisk variation",
      bpFindingsAverageDiastolicRange: "Diastolisk variation",
      bpFindingsSystolicBPload: "Systolisk blodtrycksbelastning",
      bpFindingsDiastolicBPload: "Diastolisk blodtrycksbelastning",
      bpFindingsAveragePuls: "Genomsnittlig puls",

      bpSmallerThanActive: "< i aktiv tid",

      reportLabelPatient: "Patient",
      reportPageLabel: "Sida",
      reportPatientDataPageTitle: "Grunduppgifter",
      reportUniqueIdLabel: "Arkivreferens",
      reportFindingsSectionTitle: "Fynd",

      bpReportBPmonitoring24h: "24-timmars blodtrycksuppföljning",

      ecgReportSymptomHolterDisclaimer: "Symtom-Holter är en undersökning där en specialist analyserar endast de symtomatiska punkterna markerade av patienten i inspelade data. Asymtomatiska arytmier kan förbli helt oupptäckta, och fynden som detekteras automatiskt av analysprogrammet, till exempel angående antalet extraslag, är inte exakt korrekta.",
      ecgReportSymptomFindingsTitle: "Symtom markerade av patienten",
      ecgReportAtrialFibrillationTitle: "Screening för förmaksflimmer",
      ecgReportAtrialFibrillationDisclaimer: "Screening för förmaksflimmer är en undesökning där en specialist inte går igenom det hela inspelade materialet manuellt. Några av de möjliga arytmierna kan förbli helt oupptäckta och oanalyserade, och fynden som automatiskt detekteras av analysprogrammet, till exempel angående antalet extraslag, är inte exakt korrekta.",
      ecgReportLabelSymptom: "Symtom",

      downloadedReportFilenamePrefix_sleep: "nattpolygrafi",
      downloadedReportFilenamePrefix_ecgHolter: "holter",
      downloadedReportFilenamePrefix_symptomHolter: "symtom-holter",
      downloadedReportFilenamePrefix_ecgAtrialFibrillation: "förmaksflimmer-screening",
      downloadedReportFilenamePrefix_samples: "rapportbilaga",
      downloadedReportFilenamePrefix_bloodPressure: "blodtryck",

      ecgDataOfficerFileshareFolderInvalid: "CubeDB-mappen saknas eller är ej unik",
      assignDoctorFailed: "Det gick inte att välja specialist!",

      errors_default: "Oväntat fel",
      errors_downloadReportFailed: "Nedladdningen misslyckades",

      assignDoctorSelectorLabelCurrent: "nuvarande",

      timeFilterFromLabel: "Från:",
      timeFilterToLabel: "Till:",
      timeFilterSearchButton: "Söka",
      timeFilterErrorMessage: "Startdatumet måste vara tidigare än slutdatumet.",
      timeFilterLoadingLabel: "Söker...",

      measurementListCount: "Antal rad:",
      measurementListAllColumnFilterOption: "Alla",
      measurementsGridColumnLabelDeviceSerialNumber: "Serienummer",
      invalidDeviceSerialNumber: "Serienumren på .c3s- och .edf-filer stämmer inte.",

      changeLanguageLabel: "Byta språket",
      englishLang: "🇬🇧 engelska",
      finnishLang: "🇫🇮 finska",
      displayLanguageLabel: "Språkval",
      changeLanguageFailedMessage: "Det gick inte att byta språket",

      initiateMeasurementReasonForStudyInvalid: "Remissen eller forskningsämnet måste anges",
      initiateFormButtonLabel: "Initiera",
      initiateFormButtonInProgressLabel: "Initierar...",
      initiateFailed: "Det gick inte att initiera: Server Error",

      reasonForStudyEmpty: "Remissen eller undesökningsämnet behövs",
      invalidHospitalNameLength: "Sjukhusets namn är för lång (högst 300 tecken)",
      initiatedMeasurementFailedGet: "Det gick inte att ladda registreringen.",

      orderFormLabelOperatorHospital: "Undersökningsställe",
      orderFormLabelOperatorHospitalInvalid: "Undersökningsställen måste väljas.",
      navigationOrderMeasurement: "Beställa undersökning",

      orderFormButtonLabel: "Beställa",
      orderFormButtonInProgressLabel: "Beställas...",
      orderFailed: "Det gick inte att beställa: Server Error",
      operatorHospitalsFailedGet: "Det gick inte att hämta undersökningsplatser.",

      printOrderFormLabelSsn: "Personbeteckning",
      printOrderFormInvalidLabelSsn: "Felaktig personbeteckning",
      printOrderFormLabelName: "Namn",
      printOrderFormInvalidLabelName: "Den undersöktes namn krävs",
      printOrderFormButtonLabel: "Skriv ut",
      printOrderFormButtonInProgressLabel: "Skrivs ut...",
      printOrderFormValidationErrorDefault: "Felaktiga uppgifter",
      printOrderFormLabelCode: "Patient-ID",
      printOrderFormLabelInitiatedTime: "Undersökningens beställningstid",
      printOrderFormLabelOperatorHospital: "Undersökningställe",
      printOrderFormLabelOrderingHospital: "Beställare",
      printOrderFormLabelUniqueId: "Arkivreferens",
      printOrderFormErrorGettingData: "Det gick inte att ladda ner uppgifter för att skriva ut formuläret",
      printOrderFormType: "Undersökning",

      orderingNurseCheckboxLabel: "Beställande sköterska",

      hospitalsTitle: "Användningställe",
      hospitalsFailedToGet: "Det gick inte att öppna listan över användningställen",

      editHospitalFailedToLoadHospitalDetails: "Det gick inte att ladda uppgifter på användningställe.",
      editHospitalFailedToAddOperator: "Det gick inte att tillägga operatören.",
      editHospitalOperatorHospitals: "Operatörer",
      editHospitalChooseOperatorText: " kan beställä undersökningar från följande kliniker: ",

      measurementsGridButtonTitlePrintForm: "Skriv ut bakgrundsformuläret",
      printOrderFormInvalidLabelType: "Felaktig undesökningstyp",
      printOrderFormInvalidLabelCode: "Felaktig Patient-ID",
      printOrderFormInvalidLabelUniqueId: "Felaktig arkivreferens",
      printOrderFormInvalidLabelInitiatedTime: "Felaktig initieringstid",
      printFormLabelLanguage: "Språk",
      printFormInvalidLabelLanguage: "Felaktig språk",
      printFormButtonLabel: "Skriv ut",
      swedishLang: "🇸🇪 svenska",
      russianLang: "🇷🇺 ryska",
      printFormPatientTemplateNotFound: "Formuläret kan ej hittas",
      printFormFetchError: "Det gick inte att hämta formuläret",
      printOrderFormNoOperatorInformation: "Uppgifter på undersökningställe kan ej nås.",
      measurementsGridTitleJobPending: "Väntar att slutföra operationen",
      measurementsGridTitleJobFailed: "Operationen misslyckades, kontrollera vad som hände.",
      measurementsGridTitleJobSucceeded: "Operationen lyckades.",

      eFormCodeInputErrorLabel: "Lösenordet är ogiltig",
      eFormCodeInputLabel: "Ange ditt lösenord här:",
      eFormLandingPageTitle: "Inloggning på elektronisk bakgrundsformulär",
      eFormLandingPageError: "Felaktigt lösenord",
      eFormGetFormError: "Det gick inte att hämta information. Försök igen senare.",
      eFormUpdateFormError: "Det gick inte att spara information. Försök igen senare.",

      eFormHeaderTitle: "Elektronisk bakgrundsformulär",
      eFormHeaderText_paragraph1: "Tack för att du fyller i detta formulär i elektronisk form. Med din hjälp frigörs vårdpersonalens tid för själva omvårdnadsarbetet och du hjälper samtidigt även andra människor.",
      eFormHeaderText_paragraph2: "Fyll i formuläret noggrant. Det hjälper specialisten som analyserar undersökningen för att bättre förstå situationen. Ange inte några personliga eller kontaktuppgifter i formuläret. Du kan fylla i formulären under den pågående undersökningen tills du har lämnat tillbaka undersökningsapparaten till sjukvårdspersonalen. Kom ihåg att returnera också pappersformulären, även om du inte har fyllt i den.",
      eFormFooterTitle: "Tack!",
      eFormFooterText_paragraph1: "Dina svar har sparats automatiskt. Kontrollera att du har svarat på alla frågor. Om det av någon anledning inte gick att fylla i ett fält kan du fylla i det på pappersformulären också. Returnera undersökningsenheten och pappersformuläret för bakgrundsuppgifter enligt instruktionerna.",
      initiatedMeasurementEFormLanguageNotSaved: "Språkval på formuläret har inte sparats",

      eFormSubmitButton: "Logga in",
      textTooLongError: "För lång text",

      editHospitalFailedToUpdateHospitalMeasurementTypes: "Failed to save allowed measurement types for hospital",
      editHospitalAllowedTypes: "Hospital measurement types",
      editHospitalAllowedTypesText: "can upload and initiate these types:",
      editHospitalSubmitAllowedTypes: "Submit",

      errorCannotGetHospitalTypes: "Fel. Försök igen senare.",

      editHospitalSuccessfullyUpdatedMeasurementTypes: "Forskningstyper har uppdaterats.",
      measurementsGridButtonLabelDeleteMeasurement: "Delete",
      measurementsGridButtonLabelDeleteMeasurementTooltip: "Ta bort påbörjad mätning",
      measurementsGridButtonLabelDeleteMeasurementInProgress: "Deleteing...",
      deletingMeasurementFailed: "Deleting measurement failed",
      measurementsGridButtonLabelShowComments: "Comments",
      modalResponseClose: "Close",

      commentAddingFailed: "Adding comment failed",
      commentFetchingFailed: "Fetching comments failed",

      errorCannotGetSummary: "Getting summary failed",

      labelHospitalName: "Hospital Name",

      hospitalInformationUpdateError: "Failed to update hospital details",
      hospitalInformationUpdateSuccess: "Hospital details update successful",
      hospitalInformationUpdateButton: "Hospital details update successful",
      hospitalInformationPublicName: "Public name",
      hospitalInformationPublicNameError: "Public name must be less than 100 characters",
      hospitalInformationTitle: "Hospital Information",
      hospitalInformationAddress: "Address",
      hospitalInformationAddressError: "Address must be less than 1000 characters",
      hospitalInformationCity: "City",
      hospitalInformationCityError: "City must be less than 100 characters",
      hospitalInformationOperatorAppointmentDescription: "Appointment description",
      hospitalInformationOperatorAppointmentDescriptionError: "Appointment description must be less than 1000 characters",
      hospitalInformationPostalCode: "Postal code",
      hospitalInformationPostalCodeError: "Postal code must be less than 100 characters",

      hospitalPatientFormTemplatesTitle: "Välj grunduppgiftsformulär att användas för varje undesökning",
      hospitalPatientFormTemplatesButton: "Bekräfta",
      hospitalPatientFormTemplatesError: "Det gick inte att välja grunduppgiftsformulär",
      hospitalPatientFormTemplatesSuccess: "Framgångsrikt vald grunduppgiftsformulär",

      editMeasurementDataButton: "Edit",
      editMeasurementDataUpdateInProgressLabel: "Updating...",
      editMeasurementDataUpdateButtonLabel: "Update",
      editMeasurementDataFormInvalid: "Enter valid values",
      editMeasurementDataUpdateFailureMessage: "Updating patient data failed",
      editMeasurementDataUpdateSuccessMessage: "Updating patient data succeeded",

      measurementGridAssignTechnicianSuccessful: "Technician assigned successfully",
      measurementGridAssignTechnicianError: "Technician assign failed",
      measurementsGridAssignTechnicianButton: "Assign Technician",
      measurementsGridAssignTechnicianInProgress: "Assigning...",
      measurementsGridColumnLabelTechnician: "Technician",
      measurementsGridButtonLabelAssignDoctor: "Assign Doctor",

      characterCounterCharactersRemaining: "tecken kvar",
      eformSymptomWarningLabel: "Max 60 tecken.",

      navigationLabelOldServiceModel: "Gammal service modell",

      hospitalLanguageSettingsTitle: "Standardspråk",
      hospitalLanguageSelect: "Språket",
      hospitalReportLanguageToggleCheckbox: "Bekräfta utlåtandespråket",
      hospitalLanguageSettingsUpdateButton: "Uppdatera inställningar på utlåtandespråket",
      hospitalLanguageSettingsUpdateFailure: "Det gick inte att spara inställningar på utlåtandespråket",
      hospitalLanguageSettingsUpdateSuccess: "Sparande av inställningar på utlåtandespråket lyckades",
      uploadGetContextFailed: "Det gick inte att öppna grunduppgiftsblanketten. Vänligen försök igen.",
      measurementGridReportLanguage: "Utlåtandespråket",

      analysisPatientStatusCommentsGeneratorDoneButton: "Done",
      analysisPatientStatusCommentsRegenerateButton: "Regenerate",

      generator_patientStatusComments_reasonForStudy_arrhythmiaSensations: "Arrhythmia sensations",
      generator_patientStatusComments_reasonForStudy_other: "Other",
      generator_patientStatusComments_rhythmAffectingMedication_no: "No",
      generator_patientStatusComments_rhythmAffectingMedication_yes: "Yes",
      generator_patientStatusComments_symptomsDuringRecording_no: "No",
      generator_patientStatusComments_symptomsDuringRecording_yes: "Yes",
      generator_patientStatusComments_symptomsDuringRecording_noDiary: "No diary",

      generator_label_patientStatusComments_reasonForStudy: "Reason for study",
      generator_label_patientStatusComments_rhythmAffectingMedication: "Rhythm affecting medication",
      generator_label_patientStatusComments_symptomsDuringRecording: "Symptoms during recording",

      analysisPatientStatusCommentsConfirmRegenerateButton: "Regenerate",
      analysisPatientStatusCommentsCancelRegenerateButton: "Avbryta",
      analysisRegenerationWarningLabel: "Regenerering raderar texten du har skrivit manuellt!",

      generator_label_findings_prevailingRhythm: "Prevailing rythm",
      generator_label_findings_atrialBeats: "Atrial beats",
      generator_label_findings_atrialTachycardias: "Atrial tachycardias",
      generator_label_findings_avConduction: "AV conduction",
      generator_label_findings_averageHr: "Average HR",
      generator_label_findings_commentAtrial: "Comment atrial",
      generator_label_findings_commentVentricular: "Comment ventricular",
      generator_label_findings_deltaWave: "Delta wave",
      generator_label_findings_maximumHr: "Maximum HR",
      generator_label_findings_minimumHr: "Minimum HR",
      generator_label_findings_pausesOver2_5s: "Pauses over 2,5 s",
      generator_label_findings_qrsWidth: "QRS width",
      generator_label_findings_qtTime: "QT interval",
      generator_label_findings_stSegment: "ST segment",
      generator_label_findings_symptomDiaryComment: "Symptom diary comment",
      generator_label_findings_ventricularBeats: "Ventricular beats",

      generator_button_findings_getExternalAnalysis: "Importera ecg-data",

      generator_findings_option_normal: "N",
      generator_findings_option_other: "Other",

      uploadFileFailedFileAlreadyUploaded: "Denna fil har redan sänts \\${uploadTime} av användaren \\${uploadingNurse} med Patient-ID \\${measurementCode}.",

      reportUploadFileNotForThisMeasurement: "Identifieringsuppgifterna för utlåtandet och undersökningen stämmer inte!",

      analysisDataOutdatedErrorLabel: "Parametrarna för EKG-data har ändrats i analysmjukvaran och stämmer inte överens med siffrorna i uttalandet. Importera EKG-data igen.",

      generator_label_findings_ventricularTachycardias: "Ventrikulära takykardier",
      externalFindingsMissingFieldsWarningLabel: "Some external findings missing, some sentences may not be generated",

      dataOfficerMeasurementStatusDeleted: "Poistettu",
      measurementsGridButtonLabelRestoreMeasurement: "Returnera",
      measurementsGridButtonLabelRestoreMeasurementInProgress: "Returneras",
      restoreMeasurementFailed: "Det gick inte att återställa registreringen",
      restoreMeasurementSucceeded: "Registreringen återställdes framgångsrikt",
      confirmDeleteMeasurement: "Är du säker på att du vill radera den initialiserade undesökningen ${code}? Denna ID:n kan inte återanvändas.",
      editHolterTypeButton: "Redigera Holter typ",
      setHolterTypeButton: "Sätt Holter typ",

      measurementsGridButtonLabelReassignDoctor: "Byt läkare",
      reassignMeasurementSucceeded: "Läkarbytet lyckades",
      reassignMeasurementFailed: "Det gick inte att byta läkare",
      assignFailedDoctorDoesNotHaveAccessToExternalAnalysis: "Den valda läkaren har ingen mapp i Cardiologs",
      confirmReassignDoctor: "Är du säker på att du vill byta läkare?",

      hospitalPutHospitalInChainButton: "Specifiera sjukhuskedjan",
      hospitalPutHospitalInChainTitle: "Ansluta sjukhuset till kedjan",
      hospitalPutHospitalInChainSuccess: "Anslutningen lyckades",
      hospitalPutHospitalInChainFailure: "Det gick inte att ansluta",
      hospitalPutHospitalInChainSelect: "Sjukhuskedja",

      eFormLandingPageTitle_304: "Perustietokaavake.fi",
      eFormLandingPageGreeting: "Välkommen!",
      eFormLandingPageDescription: "en anonym symtomdagbok på Internet. Dina personuppgifter varken efterfrågas eller sparas i något skede. Vänligen ange lösenordet du fick i fältet bredvid.",
      eFormLandingPageThankYou: "Tack för din medverkan!",

      measurementDetailsFileNameLabel: "Filnamn",
      measurementDetailsDataSizeLabel: "Filstorlek",

      homeHolterLandingPageTitle: "Koti-Holter.fi",
      homeHolterLandingPageDescription: "är en Internetbaserad tjänstekanal i hälsovård och är tillgänglig för både privata och offentliga kunder. Logga in genom att ange lösenordet du har fått.",
      homeHolterLandingPageCustomerService: "Kundtjänst",

      hospitalNurseSettingsCombinedReportCheckbox: "Combine reports for nurses",
      hospitalNurseSettingsUpdateButton: "Update 2fa",
      hospitalNurseSettings2FaCheckbox: "Enable 2fa for nurses from this hospital",
      hospitalNurseSettingsTitle: "Nurse 2fa",
      hospitalNurseSettingsUpdateError: "Enabling 2fa for nurses failed",
      hospitalNurseSettingsUpdateSuccess: "Enabling 2fa for nurses successful",
      externalAnalysisUrlLabel: "Länk till EKG-data",

      measurementTypeLabelHomeHolter: "Hem-Holter",
      measurementTypeLabelHomeHolter24h: "Hem-Holter 24h",
      measurementTypeLabelHomeHolter48h: "Hem-Holter 48h",
      measurementTypeLabelHomeHolter7d: "Hem-Holter 7d",

      measurementDetailsSpecifiedHomeHolterDurationLabel: "Registreringstid",

      jobAlreadyExistsMessage: "Mätningen bearbetas fortfarande. Vänta eller kontakta supporten.",

      editHospitalAllowedTypesHeaderNonHome: "Hälsostation",
      editHospitalAllowedTypesHeaderHome: "Hem",

      homeHolterDuration1dTypeLabel: "Hem-Holter 24h",
      homeHolterDuration2dTypeLabel: "Hem-Holter 48h",
      homeHolterDuration3dTypeLabel: "Hem-Holter 72h",
      homeHolterDuration4dTypeLabel: "Hem-Holter 4d",
      homeHolterDuration5dTypeLabel: "Hem-Holter 5d",
      homeHolterDuration6dTypeLabel: "Hem-Holter 6d",
      homeHolterDuration7dTypeLabel: "Hem-Holter 7d",
      homeHolterTypeLabel: "Hem-Holter",
      homeSleepTypeLabel: "Nattpolygrafi i hemmet",
      homeSymptomHolterTypeLabel: "Symptom-Holter för hemmet",
      homeEcgAtrialFibrillationTypeLabel: "Förmaksflimmer-screening för hemmet",
      homeBloodPressureTypeLabel: "Blodtrycksövervakning för hemmet",

      finishAnalysisFailedBecauseSamplesDocumentMissing: "ECG-rapporten i analysprogramvaran är ännu inte skapad!",

      mailingInfoFormNameLabel: "Namn",
      mailingInfoFormNameInvalidLabel: "Ogiltigt namn",
      mailingInfoFormStreetAddressLabel: "Postadress",
      mailingInfoFormStreetAddressInvalidLabel: "Ogiltig postadress",
      mailingInfoFormEmailAddressLabel: "E-postadress",
      mailingInfoFormPhoneNumberLabel: "Mobilnummer",
      mailingInfoFormPhoneNumberInvalidLabel: "Ogiltigt mobilnummer",
      mailingInfoFormPermissionLabel: "Jag samtycker till att mina person- och adressuppgifter lagras för postning av undersökningsutrustningen",
      mailingInfoFormOrderButtonLabel: "Beställ undersökningsutrustning",
      mailingInfoFormDeviceOrderThankYouMessage: "Tack för din beställning!",
      mailingInfoFormDeviceOrderFailedErrorMessage: "Beställningen misslyckades. Försök igen.",

      eFormWaitForDeviceMessage: "Väntar på att undersökningsutrustningen ska anlända.",

      dataOfficerFolderHasNotFinishedSyncing: "Synkroniseringen av mätmappen till OneDrive är ännu inte klar.",

      measurementsGridColumnLabelMailingInfoSetAt: "Postningsinformation angiven",
      measurementsGridColumnLabelHomeStatus: "Status",

      homeMeasurementStatusLabelWaitingForMailingInfo: "Väntar på enhetsbeställning",
      homeMeasurementStatusLabelMailingToPatient: "Väntar på enhetsleverans",

      navigationOrderHomeMeasurement: "Beställ hemundersökning",

      nurseMeasurementStatusOrdered: "Beställd",

      measurementsGridButtonLabelMailDevice: "Enhet levererad",
      mailingConfirmationButtonLabel: "Bekräfta enhetsleverans",
      mailingConfirmationButtonInProgressLabel: "Bekräftelse pågår",
      mailingDeviceConfirmationSuccess: "Enhetsleverans bekräftad",
      mailingDeviceConfirmationFailure: "Enhetsleveransbekräftelse misslyckades",

      mailDevicePageLabelDeviceSerialNumber: "Serienummer:",
      mailDevicePageDeviceSerialNumberError: "Serienumret är ogiltigt eller används redan",
      eFormHomeHeaderTitle: "Hem-Holter-tjänstens rubrik",
      eFormHomeHeaderText_paragraph1: "Instruktionstext för Hem-Holter-tjänsten",
      eFormHomeHeaderText_paragraph2: "",
      mailingConfirmationPrintMailingInfoButtonLabel: "Kopiera postinformation",
      mailingConfirmationPrintingMailingInfoSuccess: "Postinformationen har kopierats framgångsrikt till urklipp!",
      mailingConfirmationPrintingMailingInfoFailure: "Misslyckades med att kopiera postinformationen!",
      mailingInfoFormPostalCodeLabel: "Postnummer",
      mailingInfoFormPostalCodeInvalidLabel: "Ogiltigt postnummer",
      mailingInfoFormPostalAreaLabel: "Postort",
      mailingInfoFormPostalAreaInvalidLabel: "Ogiltig postort",

      measurementsGridColumnLabelDeviceMailedAt: "Enheten levererad",

    });
