import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {ListItem} from "../../list-item";
import {localizationKey, LocalizationStrings} from "../../../../i18n/i18n-model";
import {AuthenticatedUser} from "../../../authentication/authenticated-user";
import {emptyListItem} from "../list-item.component";
import {finalize} from "rxjs/operators";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {ListItemTextBuilder} from "../../list-item-text-builder.service";
import {AssignTechnicianService} from "../../assign-technician/assign-technician.service";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../../../tools/error-response";
import {DoctorAssignmentService} from "../../doctor-assignment.service";
import {ReportUploadItem} from "../../report/report-upload-item";
import {ClipboardService} from "ngx-clipboard";
import {isAnalysisCloseable, isBloodPressureMeasurement, isEcgMeasurement} from "../../../measurement-models";
import {NavigationRoute} from "../../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../../tools/navigation/route-navigator.service";
import {CloseAnalysisService} from "../../close-analysis.service";
import {CommentsCountChangedEvent} from "../../../comments/comments.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {getCommentButtonLabel} from "../../../comments/comments.utils";
import {FoundMeasurement} from "../../found-measurement";
import {RejectStudyService} from "../../reject/reject-study.service";
import {RestoreMeasurementService} from "../../restore-measurement/restore-measurement.service";
import {AuthenticationService} from "../../../authentication/authentication.service";
import {canMailDevice} from "./can-mail-device";


@Component({
  selector: "app-list-item-action-buttons",
  templateUrl: "./list-item-action-buttons.component.html",
})
export class ListItemActionButtonsComponent implements OnInit {

  @Input() item: ListItem = emptyListItem();


  @Output() measurementChanged = new EventEmitter<void>();
  @Output() deleteMeasurementClicked = new EventEmitter<void>();

  modalRefComments: BsModalRef | undefined;
  @ViewChild("templateComments") templateComments!: TemplateRef<any>;

  modalRefRejectConfirm: BsModalRef | undefined;
  @ViewChild("templateConfirmReject") templateConfirmReject!: TemplateRef<any>;

  private user!: AuthenticatedUser;

  constructor(
    private readonly listItemTextGenerator: ListItemTextBuilder,
    private readonly assignTechnicianService: AssignTechnicianService,
    private readonly notifications: Notifications,
    private readonly doctorAssignmentService: DoctorAssignmentService,
    private readonly clipboardService: ClipboardService,
    private readonly routeNavigator: RouteNavigator,
    private readonly closeEcgAnalysisService: CloseAnalysisService,
    private readonly modalService: BsModalService,
    private readonly rejectStudyService: RejectStudyService,
    private readonly restoreMeasurementService: RestoreMeasurementService,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  shouldShowAssignTechnicianButton(): Boolean {
    return this.item.foundMeasurement.type === "SLEEP"
      && this.item.foundMeasurement.status === "UPLOADED"
      && this.user.role === "DATA_OFFICER"
      && this.item.selectedDoctor !== undefined;
  }

  onAssignTechnicianClick() {
    this.item.isAssignTechnicianInProgress = true;

    const patientDataLines = this.listItemTextGenerator.build(this.item.foundMeasurement).split("\n");

    this.assignTechnicianService.assign(
      this.item.foundMeasurement.id,
      this.item.selectedDoctor!.id,
      patientDataLines
    )
      .pipe(finalize(() => this.item.isAssignTechnicianInProgress = false))
      .subscribe((newMeasurement) => {
          this.notifications.addNotification(NotificationType.OK, localizationKey("measurementGridAssignTechnicianSuccessful"));
          this.item.foundMeasurement = newMeasurement;
          this.measurementChanged.emit();
        },
        () =>
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("measurementGridAssignTechnicianError"))
      );
  }

  isAssignable(): boolean {

    const doctorAssigned = this.item.foundMeasurement.doctor !== undefined;
    const sameDoctorAssigned = this.item.selectedDoctor?.id === this.item.foundMeasurement.doctor?.id;
    const chainMeasurement = this.item.foundMeasurement.chain !== undefined;
    const assignableStatus = this.item.foundMeasurement.status === "UPLOADED" || this.item.foundMeasurement.status === "PRE_ANALYSED";
    const doctorSelected = this.item.selectedDoctor !== undefined;

    return assignableStatus &&
      (chainMeasurement || doctorSelected) &&
      (!doctorSelected || !doctorAssigned || !sameDoctorAssigned);
  }


  getAssignButtonColorClass() {
    return this.item.foundMeasurement.type === "SLEEP" && this.item.foundMeasurement.status === "UPLOADED" ? "btn-success" : "btn-primary";
  }

  shouldShowActionButtons(): boolean {
    return !this.item.showReportUpload;
  }

  onAssignClick() {

    const item = this.item;

    const patientDataLines = this.listItemTextGenerator.build(item.foundMeasurement).split("\n");

    this.doctorAssignmentService.assignDoctor(
      item.foundMeasurement.id,
      item.selectedDoctor?.id,
      patientDataLines
    )
      .subscribe(
        (newMeasurement) => {
          item.foundMeasurement = newMeasurement;
          this.measurementChanged.emit();
        },
        (errorResponse: ErrorResponse) =>
          this.notifications.addNotification(
            NotificationType.ERROR,
            this.getErrorMessageOrDefault(errorResponse)
          )
      );

  }

  private getErrorMessageOrDefault(errorResponse: ErrorResponse): keyof LocalizationStrings {
    const errorCode = singleCodeFromResponse(errorResponse);
    switch (errorCode) {
      case ErrorCode.ECG_DATA_OFFICER_FILESHARE_FOLDER_INVALID:
        return errorCode;
      case ErrorCode.INVALID_DEVICE_SERIAL_NUMBER:
        return errorCode;
      case ErrorCode.DOCTOR_DOES_NOT_HAVE_ACCESS_TO_EXTERNAL_ANALYSIS:
        return "assignFailedDoctorDoesNotHaveAccessToExternalAnalysis";
      case ErrorCode.DATA_OFFICER_FOLDER_HAS_NOT_FINISHED_SYNCING:
        return errorCode;
      default:
        return "assignDoctorFailed";
    }
  }

  getAssignButtonLabel(): keyof LocalizationStrings {
    return this.item.foundMeasurement.type === "SLEEP"
    && this.item.foundMeasurement.status === "UPLOADED"
      ? "measurementsGridButtonLabelAssignDoctor"
      : "measurementsGridButtonLabelAssign";
  }

  onUploadReportClick() {
    this.setShowReportUpload(true);
  }

  private setShowReportUpload(show: boolean) {
    this.item.showReportUpload = show;
  }

  onReportFileUploadCancelled() {
    this.setShowReportUpload(false);
  }

  onReportFileUploaded(reportUploadItem: ReportUploadItem) {
    this.item.foundMeasurement = reportUploadItem.measurement;
    this.setShowReportUpload(false);
    this.measurementChanged.emit();
  }

  canUploadReport(): boolean {
    return this.item.foundMeasurement.type === "SLEEP" && this.item.foundMeasurement.status === "READY_FOR_POST_ANALYSIS";
  }

  shouldShowCopyButton(): boolean {
    return this.user.role === "DATA_OFFICER"
      && this.item.foundMeasurement.type === "SLEEP"
      && this.item.foundMeasurement.status !== "INITIATED";
  }

  onCopyClick() {
    const text = this.listItemTextGenerator.build(this.item.foundMeasurement);
    this.clipboardService.copy(text);
  }

  shouldShowOpenAnalysisButton(): boolean {
    if (this.user.role === "DATA_OFFICER") {
      return ["INITIATED", "REJECTED_BY_DATA_OFFICER"]
          .every(status => status !== this.item.foundMeasurement.status)
        && (
          isEcgMeasurement(this.item.foundMeasurement.type)
          || isBloodPressureMeasurement(this.item.foundMeasurement.type)
        );
    }
    if (this.user.role === "DOCTOR") {
      return (isEcgMeasurement(this.item.foundMeasurement.type) || isBloodPressureMeasurement(this.item.foundMeasurement.type))
        && this.item.foundMeasurement.status === "READY_FOR_ANALYSIS";
    }

    return false;
  }

  onOpenAnalysisClick() {
    switch (this.item.foundMeasurement.type) {
      case "HOLTER_ECG":
      case "SYMPTOM_HOLTER":
      case "ECG_ATRIAL_FIBRILLATION":
        this.routeNavigator.navigateToPath(
          [NavigationRoute.ANALYSIS_ECG, this.item.foundMeasurement.id.toString()],
          {reportLanguage: this.item.foundMeasurement.reportLanguage}
        );
        break;
      case "BLOOD_PRESSURE":
        this.routeNavigator
          .navigateToPath(
            [NavigationRoute.ANALYSIS_BLOOD_PRESSURE, this.item.foundMeasurement.id.toString()],
            {reportLanguage: this.item.foundMeasurement.reportLanguage}
          );
        break;
      default:
        throw new Error(`No analyse component for measurement type ${this.item.foundMeasurement.type}.`);
    }
  }

  shouldShowCloseAnalysisButton(): boolean {
    const measurement = this.item.foundMeasurement;
    return this.user.role === "DATA_OFFICER" && isAnalysisCloseable(measurement.type) && measurement.status === "READY_FOR_POST_ANALYSIS";
  }

  onCloseAnalysisClick() {
    this.item.isCloseAnalysisInProgress = true;

    this.closeEcgAnalysisService
      .closeAnalysis(this.item.foundMeasurement.id)
      .pipe(finalize(() => (this.item.isCloseAnalysisInProgress = false)))
      .subscribe(
        (newMeasurement) => {
          this.item.foundMeasurement = newMeasurement;
          this.measurementChanged.emit();
        },
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("closeEcgAnalysisFailed"))
      );
  }

  onDeleteMeasurementClick() {
    this.deleteMeasurementClicked.emit();
  }

  shouldShowDataOfficerDeleteButton() {
    return this.user.role === "DATA_OFFICER" && this.isMeasurementDeletable();
  }

  private isMeasurementDeletable() {
    return this.item.foundMeasurement.status === "INITIATED"
      && !this.item.foundMeasurement.deleted;
  }

  shouldShowCommentsButton() {
    return this.user.role === "DATA_OFFICER" || this.user.role === "DOCTOR" || this.user.role === "NURSE";
  }

  onShowCommentsClick() {
    this.modalRefComments = this.modalService.show(this.templateComments, {
      class: "modal-lg"
    });
  }

  protected readonly getCommentButtonLabel = getCommentButtonLabel;

  onCommentsCountChanged(commentsCountChangedEvent: CommentsCountChangedEvent) {
    this.item.foundMeasurement = {
      ...this.item.foundMeasurement,
      commentCount: commentsCountChangedEvent.newCommentCount
    };
  }

  getCommentsButtonType() {
    if (this.item.foundMeasurement.commentCount === 0) {
      return "btn-secondary";
    } else {
      return "btn-primary";
    }
  }

  onReturnStudy(returnedStudy: FoundMeasurement): void {
    this.item.foundMeasurement = returnedStudy;
    this.measurementChanged.emit();
  }

  shouldShowReturnStudy(): boolean {
    return this.user.role === "DATA_OFFICER" && this.item.foundMeasurement.status === "READY_FOR_POST_ANALYSIS";
  }

  canRejectStudy(): boolean {
    return this.user.role === "DATA_OFFICER"
      && this.item.foundMeasurement.status !== "DONE"
      && this.item.foundMeasurement.status !== "REJECTED_BY_DATA_OFFICER"
      && this.item.foundMeasurement.status !== "INITIATED";
  }

  onRejectStudyClick(): void {
    this.modalRefRejectConfirm = this.modalService.show(this.templateConfirmReject, {
      class: "modal-sm",
      initialState: this.item.foundMeasurement
    });
  }

  rejectStudy(): void {

    this.modalRefRejectConfirm?.hide();

    if (!this.canRejectStudy()) {
      return;
    }

    this.item.isRejectStudyInProgress = true;

    this.rejectStudyService
      .reject(this.item.foundMeasurement.id)
      .pipe(finalize(() => (this.item.isRejectStudyInProgress = false)))
      .subscribe(
        (newMeasurement) => {
          this.item.foundMeasurement = newMeasurement;
          this.measurementChanged.emit();
        },
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("rejectStudyFailed"))
      );
  }

  shouldShowRestoreMeasurementButton() {
    return this.item.foundMeasurement.status === "INITIATED" && this.item.foundMeasurement.deleted;
  }

  restoreMeasurement() {
    this.item.isRestoreMeasurementInProgress = true;
    this.restoreMeasurementService
      .restore(this.item.foundMeasurement.id)
      .pipe(finalize(() => this.item.isRestoreMeasurementInProgress = false))
      .subscribe((newMeasurement) => {
          this.notifications.addNotification(NotificationType.OK, localizationKey("restoreMeasurementSucceeded"));
          this.item.foundMeasurement = newMeasurement;
          this.measurementChanged.emit();
        },
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("restoreMeasurementFailed")));
  }

  canMailDevice() {
    return canMailDevice(this.user.role, this.item.foundMeasurement.homeStatus);
  }

  mailDevice() {
    this.routeNavigator.navigateToPath([NavigationRoute.MAIL_DEVICE,this.item.foundMeasurement.id.toString()]);
  }
}

