<button [attr.data-testid]="testId" [type]="buttonType" [class]="buttonClass"
        [disabled]="buttonDisabled || actionInProgress" (click)="onButtonClick()"
        [title]="toolTip === undefined ? '' : toolTip | localize">
    <ng-container *ngIf="!actionInProgress; else loading">
        <i *ngIf="iconClass" [ngClass]="iconClass"></i>
        <ng-container *ngIf="buttonLabel">
            {{ buttonLabel | localize }}
        </ng-container>
    </ng-container>

    <ng-template #loading>
      <div [attr.data-testId]="testId + '-progress-indicator'">
        <span [class]="spinnerClass"></span>
        <ng-container *ngIf="actionInProgressLabel">
            {{ actionInProgressLabel | localize }}
        </ng-container>
      </div>
    </ng-template>
</button>
