import {FinnishSSN} from "finnish-ssn";
import {DateTime} from "luxon";

export function isNonRequiredTextOfMaxLength(max: number, text?: string): boolean {
  return !text || text.length <= max;
}

export function isRequiredTextOfMaxLength(max: number, text?: string): boolean {
  return !!text && text.length <= max;
}

export function isRequiredDigitsStringOfLength(length: number, text?: string): boolean {
  return !!text && text.length == length && /^[0-9]+$/.test(text);
}

export function isRequiredFormatStringOfLength(length: number, formatRegex: RegExp, text?: string): boolean {
  return !!text && text.length <= length && formatRegex.test(text);
}

export function isRequiredDigitsString(text?: string): boolean {
  return !!text && /^[0-9]+$/.test(text);
}

export function isExisting(value?: any): boolean {
  return !!value;
}

export function isEmptyOrValidSSN(value?: string | null) {
  return isExisting(value) ? FinnishSSN.validate(value!) : true;

}

export function isLimitedPositiveInteger(maxValue: number, positiveInteger?: number): boolean {
  if (!positiveInteger || !Number.isInteger(positiveInteger)) {
    return false;
  }

  const numeric = Number(positiveInteger);

  return numeric >= 1 && numeric <= maxValue;
}

export function isValidRange(minValue: number, maxValue: number, num?: number): boolean {
  if (!num || Number.isNaN(num)) {
    return false;
  }

  const numeric = Number(num);

  return numeric >= minValue && numeric <= maxValue;
}

export function isValidDateRange(isoDate1: string | undefined, isoDate2: string | undefined): boolean {

  if (!isoDate1 || !isoDate2) {
    return false;
  }

  return DateTime.fromISO(isoDate1) <= DateTime.fromISO(isoDate2);
}

export function isValidEmail(value: string): boolean {
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return emailRegex.test(String(value).toLowerCase());
}

export const BYTES_IN_KB = 1024;
export const BYTES_IN_MB: number = BYTES_IN_KB * BYTES_IN_KB;
export const BYTES_IN_GB: number = BYTES_IN_KB * BYTES_IN_MB;

export interface FileValidationOptions {
  minFileSize: number;
  maxFileSize: number;
  requiredExtensions?: string[];
  fileNameValidator?: (name: string) => boolean;
}

export function isValidFile(file: File | undefined, options: FileValidationOptions): boolean {
  if (!file) {
    return false;
  }

  const [name, size] = [file.name || "", file.size || 0];

  const extensionValid = !!options.requiredExtensions ? options.requiredExtensions.some(extension => name.toLowerCase().endsWith(`.${extension}`)) : true;
  const sizeValid = size >= options.minFileSize && size <= options.maxFileSize;
  const fileNameValid = !!options.fileNameValidator ? options.fileNameValidator(stripFileNameExtension(name)) : true;

  return extensionValid && sizeValid && fileNameValid;
}

function stripFileNameExtension(name: string): string {
  return name.substring(0, name.lastIndexOf(".")) || name;
}

export function isValidMeasurementCode(code?: string | null): boolean {
  const validFormatRegex = /^[0-9a-zA-Z-_]$|^[0-9a-zA-Z-_][0-9a-zA-Z- _]{0,18}[0-9a-zA-Z-_]$/;
  const ssnRegex = /(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-U-Y]|[012]\d[A-F])\d{3}[\dA-Z]/;

  const validFormat = code?.match(validFormatRegex) != null;
  const doesNotContainSsn = code?.toUpperCase()?.match(ssnRegex) == null;

  return validFormat && doesNotContainSsn;
}
