import {Component} from "@angular/core";
import {FormFieldConfig, PropertyChangeListener} from "../../../../tools/form/form-field-config";
import {EFormIdProvider} from "../../e-form-id-provider.service";
import {ActivatedRoute} from "@angular/router";
import {EFormMailingInfoService, MailingInfo} from "./e-form-mailing-info.service";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {FormConfigBase} from "../../../../tools/form/form-config-base";
import {MailingInfoValidator} from "./mailing-info-validator";

export interface MailingInfoFormModel {
  name: string;
  streetAddress: string;
  postalCode: string;
  postalArea: string;
  emailAddress: string;
  phoneNumber: string;
  permission: boolean;
}

@Component({
  selector: "eform-mailing-info",
  templateUrl: "./e-form-mailing-info.component.html",
})
export class EFormMailingInfoComponent {

  private model: MailingInfoFormModel = {
    postalArea: "",
    postalCode: "",
    streetAddress: "",
    name: "",
    phoneNumber: "",
    emailAddress: "",
    permission: false
  };

  private validator: MailingInfoValidator = new MailingInfoValidator(this.model);

  constructor(
    private readonly mailingInfoService: EFormMailingInfoService,
    private readonly eFormCodeProvider: EFormIdProvider,
    private readonly route: ActivatedRoute,
    private readonly notifications: Notifications
  ) {
    const self = this;

    const propertyChangeListener: PropertyChangeListener<MailingInfoFormModel> = {
      onPropertyChange(property: keyof MailingInfoFormModel, newValue: any) {
        self.model[property] = newValue as never;
        self.orderingEnabled = self.model.permission;
        self.validator.updatePropertyValidation(property);
      }
    };

    this.formConfig = new MailingInfoFormConfig(
      this.model,
      propertyChangeListener,
      this.validator
    );

  }

  readonly formConfig: MailingInfoFormConfig;

  orderingEnabled = false;

  deviceOrdered = false;

  orderDevice() {

    this.validator.updateAllValidations();
    if (!this.validator.isFormValid()) {
      return;
    }

    const eFormId = this.eFormCodeProvider.getEFormId(this.route);

    this.mailingInfoService.orderDevice(eFormId!, this.formModelToMailingInfo())
      .subscribe({
        next: () => {
          this.deviceOrdered = true;
        },
        error: () => {
          this.notifications.addNotification(
            NotificationType.ERROR,
            "mailingInfoFormDeviceOrderFailedErrorMessage"
          );
        }
      });
  }

  private formModelToMailingInfo(): MailingInfo {
    return {
      name: this.model.name,
      postalArea: this.model.postalArea,
      postalCode: this.model.postalCode,
      streetAddress: this.model.streetAddress,
      phoneNumber: this.model.phoneNumber,
      emailAddress: this.model.emailAddress
    };
  }
}


class MailingInfoFormConfig extends FormConfigBase<MailingInfoFormModel> {

  readonly name: FormFieldConfig<MailingInfoFormModel>;
  readonly emailAddress: FormFieldConfig<MailingInfoFormModel>;
  readonly phoneNumber: FormFieldConfig<MailingInfoFormModel>;
  readonly permission: FormFieldConfig<MailingInfoFormModel>;
  readonly streetAddress: FormFieldConfig<MailingInfoFormModel>;
  readonly postalCode: FormFieldConfig<MailingInfoFormModel>;
  readonly postalArea: FormFieldConfig<MailingInfoFormModel>;

  constructor(
    model: MailingInfoFormModel,
    propertyChangeListener: PropertyChangeListener<MailingInfoFormModel>,
    validator: MailingInfoValidator
  ) {
    super(
      model,
      propertyChangeListener,
      (property) => validator.isPropertyValid(property)
    );

    this.name = this.createField({
      type: "text",
      required: true,
      label: "mailingInfoFormNameLabel",
      invalidLabel: "mailingInfoFormNameInvalidLabel",
      property: "name",
    });

    this.streetAddress = this.createField({
      type: "text",
      required: true,
      label: "mailingInfoFormStreetAddressLabel",
      invalidLabel: "mailingInfoFormStreetAddressInvalidLabel",
      property: "streetAddress",
    });

    this.postalCode = this.createField({
      type: "text",
      required: true,
      label: "mailingInfoFormPostalCodeLabel",
      invalidLabel: "mailingInfoFormPostalCodeInvalidLabel",
      property: "postalCode"
    });

    this.postalArea = this.createField({
      type: "text",
      required: true,
      label: "mailingInfoFormPostalAreaLabel",
      invalidLabel: "mailingInfoFormPostalAreaInvalidLabel",
      property: "postalArea"
    });

    this.emailAddress = this.createField({
      type: "text",
      required: false,
      label: "mailingInfoFormEmailAddressLabel",
      property: "emailAddress"
    });

    this.phoneNumber = this.createField({
      type: "text",
      required: true,
      label: "mailingInfoFormPhoneNumberLabel",
      invalidLabel: "mailingInfoFormPhoneNumberInvalidLabel",
      property: "phoneNumber"
    });

    this.permission = this.createField({
      type: "checkbox",
      required: true,
      label: "mailingInfoFormPermissionLabel",
      property: "permission"
    });
  }
}


export function isMailingInfoComplete(mailingInfo: MailingInfoFormModel): boolean {
  return mailingInfo.name.length > 0
    && mailingInfo.phoneNumber.length > 0
    && mailingInfo.permission;
}
