import {Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, of, Subject} from "rxjs";
import {debounceTime, finalize, switchMap, tap} from "rxjs/operators";
import {SubSink} from "subsink";
import {localizationKey, LocalizationStrings} from "../../i18n/i18n-model";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {PropertyChangeListener} from "../../tools/form/form-field-config";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {ALL_MEASUREMENT_TYPES, MeasurementType} from "../measurement-models";
import {emptyEcgSymptom, MAX_ECG_SYMPTOMS, MeasurementExistsResponse, PatientData} from "./upload-model";
import {mapFormToData, mapFormToUploadInitiatedData} from "./upload-data-mapper";
import {UploadFormConfig} from "./upload-form-config";
import {emptyUploadFormModel, UploadFormModel} from "./upload-form-model";
import {UploadValidator} from "./upload-validator";
import {UploadService} from "./upload.service";
import {ActivatedRoute} from "@angular/router";
import {MeasurementIdProvider} from "../analize/measurement-id-provider";
import {HospitalMeasurementTypes} from "../hospitals/edit-hospital/hospital-measurement-types.service";
import {uploadFormMutuallyDependentProperties} from "./create-measurement-form-validation-map";
import {InitiatedMeasurementData, NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";
import {addEmptyEcgSymptoms} from "../utils";
import {getTypeSelectionOptions} from "../measurement-type-select-options";
import {HospitalLanguageSettings} from "../hospitals/edit-hospital/edit-hospital.service";
import {getNurseMeasurementType} from "../get-nurse-measurement-type";
import {SelectionOptions} from "../../tools/form/form-field.component";
import {Nurse} from "../list/found-measurement";

export interface UploadContext {
  initiatedMeasurementData?: InitiatedMeasurementData;
  allowedMeasurementTypes: HospitalMeasurementTypes;
  languageSettings: HospitalLanguageSettings;
}

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
})
export class UploadComponent implements PropertyChangeListener<UploadFormModel>, OnDestroy, OnInit {
  readonly uploadFormModel: UploadFormModel = emptyUploadFormModel();

  formConfig?: UploadFormConfig;

  uploadValidator = new UploadValidator(this.uploadFormModel);

  private errorMessages = new Map<ErrorCode, keyof LocalizationStrings>()
    .set(ErrorCode.CODE_NOT_UNIQUE, "uploadFailedCodeNotUnique")
    .set(ErrorCode.FILE_NAME_NOT_UNIQUE, "uploadFailedFileNameNotUnique");
  private readonly propertyChangeMeasurementCode = new Subject<string>();
  readonly propertyChangeMeasurementCode$: Observable<string> = this.propertyChangeMeasurementCode.asObservable();
  measurementCodeExists = false;
  private readonly codeCheckInProgress = new Subject<boolean>();
  readonly codeCheckInProgress$: Observable<boolean> = this.codeCheckInProgress.asObservable();
  private readonly uploadInProgress = new Subject<boolean>();
  readonly uploadInProgress$: Observable<boolean> = this.uploadInProgress.asObservable();

  private readonly subSink = new SubSink();
  errorMessage?: keyof LocalizationStrings;
  measurementId?: number;
  reportLanguageEnabled = false;
  showLoadingSpinner = true;
  showError = false;

  showForm = false;

  private hospitalLanguage?: string;

  constructor(
    private readonly uploadService: UploadService,
    private readonly notifications: Notifications,
    private readonly navigator: RouteNavigator,
    private readonly route: ActivatedRoute,
    private readonly measurementIdProvider: MeasurementIdProvider
  ) {
    this.subSink.add(
      this.propertyChangeMeasurementCode$.pipe(
        tap(() => this.codeCheckInProgress.next(true)),
        debounceTime(500),
        switchMap((measurementCode: string) => {
          if (measurementCode) {
            return this.uploadService.doesMeasurementExist(measurementCode);
          }

          return of({exists: false} as MeasurementExistsResponse);
        })
      ).subscribe({
        next: (response) => {
          this.codeCheckInProgress.next(false);
          this.measurementCodeExists = response.exists;
          this.errorMessage = this.measurementCodeExists ? "uploadErrorCodeNotUnique" : undefined;
        },
        error: (error) => {
          console.error(error);
          this.codeCheckInProgress.next(false);
        },
      })
    );
  }

  ngOnInit(): void {
    this.measurementId = this.measurementIdProvider.getId(this.route);

    this.uploadService.getUploadContext(this.measurementId)
      .pipe(
        finalize(() => this.showLoadingSpinner = false)
      )
      .subscribe(
        (uploadContext) => {
          this.initialiseUploadForm(uploadContext);
          this.showForm = true;
        },
        () => this.showError = true
      );
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  shouldShowFileUpload(): boolean {
    return this.isTypeSelected();
  }

  shouldShowForm(type: MeasurementType): boolean {
    return this.uploadFormModel.measurementType === type;
  }

  isTypeSelected(): boolean {
    return !!this.uploadFormModel.measurementType;
  }

  onPropertyChange(property: keyof UploadFormModel, newValue: any): void {
    if (property === "measurementType") {
      const measurementCode = this.uploadFormModel.measurementCode;
      Object.assign(this.uploadFormModel, emptyUploadFormModel());
      this.uploadFormModel.symptoms.length = 0;
      this.uploadFormModel.symptoms.push(...new Array(MAX_ECG_SYMPTOMS).fill(0).map((_) => emptyEcgSymptom()));
      this.uploadFormModel.reportLanguage = this.hospitalLanguage;
      this.uploadValidator.resetValidationStatuses();
      this.errorMessage = undefined;
      if (measurementCode !== "") {
        this.uploadFormModel.measurementCode = measurementCode;
        this.uploadValidator.updatePropertyValidation("measurementCode");
        this.propertyChangeMeasurementCode.next(measurementCode);
      }
    }
    (this.uploadFormModel as any)[property] = newValue;
    this.uploadValidator.updatePropertyValidation(property);

    if (uploadFormMutuallyDependentProperties.includes(property)) {
      uploadFormMutuallyDependentProperties.forEach((p) => this.uploadValidator.updatePropertyValidation(p));
    }

    if (property === "measurementCode") {
      this.propertyChangeMeasurementCode.next(newValue);
    }
  }

  onUploadClick(): void {
    this.uploadValidator.updateAllValidations();

    const isValid = this.uploadValidator.isFormValid();

    if (!isValid) {
      this.errorMessage = "uploadFormValidationErrorDefault";
      return;
    }

    this.uploadInProgress.next(true);

    const uploadObservable = this.measurementId
      ? this.uploadService.uploadInitiatedMeasurement(mapFormToUploadInitiatedData(this.uploadFormModel, this.measurementId))
      : this.uploadService.upload(mapFormToData(this.uploadFormModel));

    uploadObservable
      .pipe(finalize(() => this.uploadInProgress.next(false)))
      .subscribe(
        () => {
          Object.assign(this.uploadFormModel, emptyUploadFormModel());
          this.notifications.addNotification(NotificationType.OK, localizationKey("uploadSucceeded"));
          this.navigator.navigateTo(NavigationRoute.LIST);
        },
        (errorResponse: ErrorResponse) =>
          (this.errorMessage = this.errorMessages.get(singleCodeFromResponse(errorResponse)) || "uploadFailed")
      );
  }

  private mapPatientDataToUploadFormModel(patientData: PatientData): void {
    Object.keys(patientData).forEach(property => {
      // @ts-ignore
      this.uploadFormModel[property] = patientData[property];
    });

  }

  private initialiseUploadForm(uploadContext: UploadContext) {

    const data = uploadContext.initiatedMeasurementData;

    if (data !== undefined) {
      this.uploadFormModel.measurementCode = data.code;
      this.uploadFormModel.measurementType = getNurseMeasurementType(
        data.type,
        data.isHome,
        {
          specifiedHolterDuration: data.specifiedDuration
        }
      );
      const patientData = addEmptyEcgSymptoms(data.patientData!);
      this.mapPatientDataToUploadFormModel(patientData);
    }

    const typeSelectionOptions: SelectionOptions<NurseMeasurementType> = getTypeSelectionOptions(
      data === undefined
        ? {
          types: uploadContext.allowedMeasurementTypes.types,
          homeTypes: []
        }
        : {
          types: data.isHome ? [] : [data.type],
          homeTypes: data.isHome ? [data.type] : []
        }
    );

    this.formConfig = new UploadFormConfig(
      this.uploadFormModel,
      this,
      (property) => this.uploadValidator.isPropertyValid(property),
      this.measurementId !== undefined,
      data ? data.isOrdered : false,
      typeSelectionOptions
    );

    this.reportLanguageEnabled = uploadContext.languageSettings.reportLanguageEnabled;
    if (this.reportLanguageEnabled) {
      this.hospitalLanguage = uploadContext.languageSettings.language;
      this.uploadFormModel.reportLanguage = uploadContext.languageSettings.language;
    }
  }

  protected readonly localizationKey = localizationKey;

  mapNurseMeasurementTypeToMeasurementType(nurseMeasurementType: NurseMeasurementType | undefined): MeasurementType | undefined {
    if (nurseMeasurementType === undefined) {
      return undefined;
    }
    if (
      ["HOME_HOLTER_24H",
        "HOME_HOLTER_48H",
        "HOME_HOLTER_7D"]
        .includes(nurseMeasurementType)
    ) {
      return "HOLTER_ECG";
    }
    return nurseMeasurementType as MeasurementType;
  }
}
