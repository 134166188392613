import {SelectionOption, SelectionOptions} from "../tools/form/form-field.component";
import {getMeasurementTypeLocalizationKey, MeasurementType} from "./measurement-models";
import {localizationKey} from "../i18n/i18n-model";
import {NurseMeasurementType} from "./initiate-measurement/initiate-measurement-data";
import {HospitalMeasurementTypes} from "./hospitals/edit-hospital/hospital-measurement-types.service";

export const measurementTypeOptions =
  SelectionOptions.with<MeasurementType>(
    {
      value: "SLEEP",
      text: localizationKey(getMeasurementTypeLocalizationKey("SLEEP")),
    },
    {
      value: "HOLTER_ECG",
      text: localizationKey(getMeasurementTypeLocalizationKey("HOLTER_ECG")),
    },
    {
      value: "ECG_ATRIAL_FIBRILLATION",
      text: localizationKey(getMeasurementTypeLocalizationKey("ECG_ATRIAL_FIBRILLATION")),
    },
    {
      value: "SYMPTOM_HOLTER",
      text: localizationKey(getMeasurementTypeLocalizationKey("SYMPTOM_HOLTER")),
    },
    {
      value: "BLOOD_PRESSURE",
      text: localizationKey(getMeasurementTypeLocalizationKey("BLOOD_PRESSURE")),
    }
  );


export function getTypeSelectionOptions(allowedTypes: HospitalMeasurementTypes): SelectionOptions<NurseMeasurementType> {

  const options: SelectionOption<NurseMeasurementType>[] = measurementTypeOptions
    .items
    .filter(selectionOption =>
      allowedTypes.types.includes(selectionOption.value)
    );

  if (allowedTypes.homeTypes.includes("HOLTER_ECG")) {
    options.push(
      {
        value: "HOME_HOLTER_24H",
        text: localizationKey("measurementTypeLabelHomeHolter24h"),
      },
      {
        value: "HOME_HOLTER_48H",
        text: localizationKey("measurementTypeLabelHomeHolter48h"),
      },
      {
        value: "HOME_HOLTER_7D",
        text: localizationKey("measurementTypeLabelHomeHolter7d"),
      },
    );
  }

  if (allowedTypes.homeTypes.includes("SLEEP")) {
    options.push(
      {
        value: "HOME_SLEEP",
        text: localizationKey("homeSleepTypeLabel"),
      },
    );
  }
  if (allowedTypes.homeTypes.includes("BLOOD_PRESSURE")) {
    options.push(
      {
        value: "HOME_BLOOD_PRESSURE",
        text: localizationKey("homeBloodPressureTypeLabel"),
      },
    );
  }
  if (allowedTypes.homeTypes.includes("ECG_ATRIAL_FIBRILLATION")) {
    options.push(
      {
        value: "HOME_ECG_ATRIAL_FIBRILLATION",
        text: localizationKey("homeEcgAtrialFibrillationTypeLabel"),
      }
    );
  }
  if (allowedTypes.homeTypes.includes("SYMPTOM_HOLTER")) {
    options.push(
      {
        value: "HOME_SYMPTOM_HOLTER",
        text: localizationKey("homeSymptomHolterTypeLabel"),
      }
    );
  }
  return {
    items: options,
    isColumnLayout: measurementTypeOptions.isColumnLayout
  };
}

